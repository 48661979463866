import React, { useState } from "react";
import Navbar from "../../components/Navbar";

const OtpConfirmation = () => {
    const [otp, setOtp] = useState(new Array(4).fill(""));
    const handleMaxLen = (e: any) => {
        if (e.target.value.length > e.target.maxLength) {
            e.target.value = e.target.value.slice(0, e.target.maxLength);
        }
    };
    return (
        <>
            <Navbar showArrow showDineIn />
            <div className="otpconfirmation-page">
                <div className="Verification-page container">
                    <div className="mobileframe">
                        <img src="../Images/mobileframe.png" alt="image" />
                    </div>
                    <h1>Verify your number</h1>
                    <p>
                        Enter the 4 digit number that was sent to you via SMS.
                    </p>
                    <div className="confirmation-code">
                        {otp?.map((data, index) => {
                            return (
                                <input
                                    className="otp-field"
                                    type="number"
                                    key={index}
                                    maxLength={1}
                                    placeholder="4"
                                    onInput={handleMaxLen}
                                />
                            );
                        })}
                    </div>
                    <div className="resend-code">Resend Code</div>
                </div>
            </div>
        </>
    );
};

export default OtpConfirmation;
