import { loaderAction, LOGIN_WITH_GOOGLE, V_LOGIN } from "../../const";

export interface LoginReducer {
    vlogin: any;
    googleauth: any;
    loader: boolean;
}
const initialState = {
    vlogin: {},
    googleauth: {},
    vtoken: "",
    loader: false,
};
const vLoginReducer = (state = initialState, { type, payload }: any) => {
    switch (type) {
        case V_LOGIN:
            return {
                ...state,
                vlogin: payload,
                vtoken: payload.data.token,
                loader: false,
            };
        case LOGIN_WITH_GOOGLE:
            return {
                ...state,
                googleauth: payload,
                vtoken: payload.token,
                loader: false,
            };
        case loaderAction.GET_LOADER:
            return {
                ...state,
                loader: payload,
            };
        default:
            return state;
    }
};
export default vLoginReducer;
