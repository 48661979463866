import React from "react";
import LandingFooter from "../../components/Landing/LandingFooter";
import LandingNav from "../../components/Landing/LandingNav";
import ReadyToStart from "../../components/Landing/ReadyToStart";
import Privacy from "../../components/Terms/Privacy";
import Terms from "../../components/Terms/Terms";

const TermsPrivacy = () => {
    return (
        <>
            {/* <LandingNav /> */}
            <div className="terms_privacy__wrapper">
                <Terms />
                <Privacy />
            </div>
            {/* <ReadyToStart/>
    <LandingFooter/> */}
        </>
    );
};

export default TermsPrivacy;
