import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ApiFeedbackOrder, FeedbackBodyType } from "../../store/api/feedback";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Navbar from "../Navbar";
import AppButton from "../AppButton";

const Feedback = () => {
    const dispatch = useDispatch<any>();
    const { currentVenue } = useParams();
    const ref: any = useRef();
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        review: "",
        restaurant: currentVenue || "",
    });
    let navigate = useNavigate();
    const handleSubmit = async (values: any) => {
        setFormData(values);

        const toSend = {
            name: values.name,
            email: values.email,
            review: values.review,
            restaurant: currentVenue || "",
        };
        let data = new FormData();
        for (const [key, value] of Object.entries(toSend)) {
            data.append(key, value);
        }
        currentVenue &&
            dispatch(
                ApiFeedbackOrder(data, { currentVenue, navigate: navigate })
            );

        if (ref?.current) ref?.current.resetForm();
    };
    const handleChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData({ ...formData, [name]: value });
    };
    const feedbackInfo = {
        name: "",
        email: "",
        review: "",
    };
    const validationSchema = Yup.object({
        name: Yup.string()
            .trim()
            .min(4, "Name must be atleast 4 letters")
            .required("Please enter your name!"),
        email: Yup.string()
            .email("Invalid email!")
            .required("Please enter your email!"),
        review: Yup.string()
            .trim()
            .min(10, "Review must be atleast 10 letters")
            .required("Please enter your message!"),
    });
    return (
        <>
            <Navbar showArrow showLogo={true} venue={currentVenue} />
            <div className="feedback_wrapper">
                <Formik
                    innerRef={ref}
                    initialValues={feedbackInfo}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                    }) => {
                        const { name, email, review } = values;
                        return (
                            <form
                                className="feedback_container"
                                onSubmit={handleSubmit}
                            >
                                <h1>Send Us Your Feedback!</h1>
                                <div className="feedback_inputfield">
                                    <label htmlFor="name">Name</label>
                                    <input
                                        type="text"
                                        placeholder="Name"
                                        onChange={handleChange("name")}
                                        name="name"
                                        value={name}
                                    />
                                    {touched.name && errors.name && (
                                        <p className="errmsg">{errors.name}</p>
                                    )}
                                </div>
                                <div className="feedback_inputfield">
                                    <label htmlFor="email">Email</label>
                                    <input
                                        type="text"
                                        placeholder="Email"
                                        onChange={handleChange("email")}
                                        name="email"
                                        value={email}
                                    />
                                    {touched.email && errors.email && (
                                        <p className="errmsg">{errors.email}</p>
                                    )}
                                </div>
                                <div className="feedback_descriptionfield">
                                    <label htmlFor="message">Message</label>
                                    <textarea
                                        className="description_textarea"
                                        placeholder="Message"
                                        onChange={handleChange("review")}
                                        name="review"
                                        value={review}
                                    />
                                    {touched.review && errors.review && (
                                        <p className="errmsg">
                                            {errors.review}
                                        </p>
                                    )}
                                </div>
                                <AppButton btnType="primary">Submit</AppButton>
                                {/* <div className="feedback_submit">
                                    <button type="submit">Submit</button>
                                </div> */}
                            </form>
                        );
                    }}
                </Formik>

                {/* <h1>Sticky styles for better touch screen buttons!</h1>

<button>Normal button</button>

<button className="delayed"><a href="https://www.google.com">Delayed style</a></button>

<p>The CSS :active psuedo style is displayed between the time when a user touches down (when finger contacts screen) on a element to the time when the touch up (when finger leaves the screen) occures.   With a typical touch-screen tap interaction, the time of which the :active psuedo style is displayed can be very small resulting in the :active state not showing or being missed by the user entirely.  This can cause issues with users not undertanding if their button presses have actually reigstered or not.</p>

<p>Having the the :active styling stick around for a few hundred more milliseconds after touch up would would improve user understanding when they have interacted with a button.</p> */}
            </div>
        </>
    );
};

export default Feedback;
