import React from "react";
import { Link } from "react-router-dom";
import PackagePlan from "../../components/PackagePlan";
const Plan = () => {
    return (
        <>
            <div className="form_wrapper vmenu_plan">
                <Link to="/">
                    <div className="side_logo">
                        <img src="/Images/logoII.png" alt="logo" />
                    </div>
                </Link>
                <PackagePlan
                    title="PICK YOUR PLAN"
                    content="Connect your audience to everything that you do "
                />
            </div>
        </>
    );
};

export default Plan;
