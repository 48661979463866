import React, { useState, useEffect } from "react";

import { FiMinus } from "react-icons/fi";
import { IoIosAdd } from "react-icons/io";

import { FaPencilAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { StateType } from "../../store/Reducer/index.reducer";
import { handleDelete, handleUpdateCart } from "../../store/api/cart";

import { CartType } from "../../store/Type/cart.type";
import Empty from "../Empty";
import { Modal } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { displayLoader } from "../../store/Action/loader.action";
import Pricing from "../Pricing";
import text_truncate from "../TextTruncate";
import { ApiCreateOrder } from "../../store/api/order";
import { CartReducar } from "../../store/Reducer/cart.reducer";
import AppButton from "../AppButton";

interface Props {
    disabledButton?: boolean;
    cart: CartReducar;
    tablenum: number;
    inputName: string;
    phonenum: any;
}
const Index = ({
    disabledButton,
    cart,
    tablenum,
    inputName,
    phonenum,
}: Props) => {
    const { currentVenue } = useParams();
    const [note, setNote] = useState("");
    const [isModalVisible, setIsModalVisible] = useState({
        status: false,
        productId: "",
        productKey: "",
    });

    let navigate = useNavigate();
    const [formData, setFormData] = useState({
        kitchen_note: "",
    });

    const handleOk = async (productId: string, productKey: string) => {
        await dispatch(handleDelete(productId, productKey));
        setIsModalVisible({ status: false, productId: "", productKey: "" });
    };

    const handleCancel = () => {
        setIsModalVisible({ status: false, productId: "", productKey: "" });
    };

    const dispatch = useDispatch<any>();
    const { product: Product, totalQty } = cart;
    const curr = useSelector(
        (state: StateType) => state.category.categoryRes.currency
    );

    const updateQuantity = (
        productId: string,
        quantity: number,
        productKey: string
    ) => {
        let data = {
            productId,
            quantity,
            productKey,
        };

        dispatch(displayLoader(true));
        if (quantity < 1) {
            setIsModalVisible({ status: true, productId, productKey });
            dispatch(displayLoader(false));
        } else {
            dispatch(displayLoader(true));
            dispatch(handleUpdateCart(data));
        }
    };
    const handleConfirm = () => {
        const data: any = {
            table: tablenum,
            name: inputName,
            kitchen_note: note,
            phone: phonenum,
        };
        tablenum &&
            dispatch(
                ApiCreateOrder(data, { currentVenue, navigate: navigate })
            );
        dispatch(displayLoader(true));
    };
    return (
        <>
            {totalQty > 0 ? (
                <div className="table-wrapper">
                    {Product?.map((item: CartType, index: number) => {
                        return (
                            <>
                                <div className="table-container" key={index}>
                                    <div className="table__left">
                                        <h4>
                                            {text_truncate({
                                                str: item.name,
                                                length: 25,
                                                ending: null,
                                            })}
                                        </h4>
                                        <div className="tabletopping__wrapper">
                                            {Array.isArray(item.topping) &&
                                                item?.topping?.map(
                                                    (data, i) => {
                                                        return (
                                                            <>
                                                                <div
                                                                    className="table_topping"
                                                                    key={i}
                                                                >
                                                                    <span>
                                                                        {
                                                                            data.name
                                                                        }
                                                                    </span>
                                                                    <span>
                                                                        +
                                                                        {
                                                                            data.price
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </>
                                                        );
                                                    }
                                                )}
                                        </div>

                                        <div className="item__total-price">
                                            {curr}&nbsp;
                                            {item.unitPrice}
                                        </div>
                                    </div>

                                    <div className="table__right">
                                        <div
                                            className="inc-dec"
                                            onClick={() =>
                                                +item.quantity > 0 &&
                                                updateQuantity(
                                                    item.productId,
                                                    +item.quantity - 1,
                                                    item.productKey
                                                )
                                            }
                                        >
                                            <FiMinus />
                                        </div>
                                        <div className="inc-dec__num">
                                            {item.quantity}
                                        </div>
                                        <div
                                            className="inc-dec"
                                            onClick={() =>
                                                updateQuantity(
                                                    item.productId,
                                                    +item.quantity + 1,
                                                    item.productKey
                                                )
                                            }
                                        >
                                            <IoIosAdd />
                                        </div>
                                    </div>
                                </div>
                            </>
                        );
                    })}
                    <div className="table-button">
                        <Link
                            className="additem_link"
                            to={`/menu/${currentVenue}/category`}
                        >
                            <button>
                                <IoIosAdd className="add-icon" />
                                Add Items
                            </button>
                        </Link>
                    </div>
                    <div className="kitchen_note">
                        <div className="kitchen_noteleft">
                            <FaPencilAlt className="filltag" /> Note To Kitchen
                        </div>

                        <div className="notebox">
                            <textarea
                                placeholder="Write something you want here"
                                value={note}
                                name="note"
                                onChange={(e) => setNote(e.target.value)}
                            ></textarea>
                        </div>
                    </div>
                    {/* <div className="voucher-content">
                        <div className="voucher-content-left">
                            <BsFillTagsFill className="filltag" /> Add voucher
                            or discount code
                        </div>
                        <BiChevronRight className="table__right-arrow" />
                    </div> */}

                    <div className="ordertable_footer">
                        <Pricing />
                        <AppButton
                            btnType="primary"
                            onClick={handleConfirm}
                            disabled={disabledButton ? true : false}
                            className={
                                disabledButton
                                    ? "continuebtn"
                                    : "continuebtnactive"
                            }
                        >
                            Confirm
                        </AppButton>
                    </div>
                </div>
            ) : (
                <Empty />
            )}

            <Modal
                className="remove_modal"
                title="Remove Item?"
                visible={isModalVisible.status}
                onCancel={handleCancel}
            >
                <p>Are you sure you want to remove this order ?</p>
                <div className="removemodal_footer">
                    <button onClick={handleCancel}>Cancel</button>
                    <button
                        onClick={() =>
                            handleOk(
                                isModalVisible.productId,
                                isModalVisible.productKey
                            )
                        }
                    >
                        Remove
                    </button>
                </div>
            </Modal>
        </>
    );
};

export default Index;
