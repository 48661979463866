import { Link } from "react-router-dom";
import AppButton from "../AppButton";

const FailedToVerify = () => {
    return (
        <div className="form_wrapper">
            <Link to="/">
                <div className="side_logo">
                    <img src="/Images/logoII.png" alt="logo" />
                </div>
            </Link>
            <div className="form_container ">
                <h1 className="signup_heading verify_content">
                    Sorry we are unable to verify your account
                </h1>
                <Link to="/virtual-menu/signup" className="form_backbtn">
                    <AppButton btnType="lgbutton">Go Back to Sign up</AppButton>
                </Link>
            </div>
        </div>
    );
};
export default FailedToVerify;
