import {
    BUSINESS_TYPE,
    LOGIN_WITH_GOOGLE,
    V_LOGIN,
    V_RESTAURANT,
    V_SIGN_UP,
    V_USER,
} from "../const";

export const signupForm = (data: any) => {
    return {
        type: V_SIGN_UP,
        payload: data,
    };
};
export const loginForm = (data: any) => {
    return {
        type: V_LOGIN,
        payload: data,
    };
};
export const userForm = (data: any) => {
    return {
        type: V_USER,
        payload: data,
    };
};
export const restaurantForm = (data: any) => {
    return {
        type: V_RESTAURANT,
        payload: data,
    };
};
export const loginWithGoogle = (data: any) => {
    return {
        type: LOGIN_WITH_GOOGLE,
        payload: data,
    };
};
export const getBusinessType = (data: any) => {
    return {
        type: BUSINESS_TYPE,
        payload: data,
    };
};
