import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { handleUserform } from "../../store/api/view-admin/user-register";
import AppButton from "../AppButton";
import Country from "./Country";
import { Formik } from "formik";
import * as Yup from "yup";
import { StateType } from "../../store/Reducer/index.reducer";
import Loader from "../Loader";
const AboutUser = () => {
    const [formData, setFormData] = React.useState({
        f_name: "",
        l_name: "",
        country: "",
        phone: "",
        countrycode: "",
    });
    const loader = useSelector((state: StateType) => state.vsignup.loader);

    const dispatch = useDispatch<any>();

    let navigate = useNavigate();

    const handleChange = (value?: any, mode?: any, e?: any) => {
        if (e) {
            const name = e.target.name;
            const value = e.target.value;
            setFormData({ ...formData, [name]: value });
        }
        if (mode == "country_name") {
            setFormData({ ...formData, country: value });
        } else {
            setFormData({ ...formData, countrycode: value });
        }
    };
    const handleChangeII = (e: any) => {};
    const handleSubmit = (e: any) => {
        const toSend = {
            f_name: e.f_name,
            l_name: e.l_name,
            country: e.country,
            phone: e.phone,
            countrycode: e.countrycode,
        };
        let data = new FormData();
        for (const [key, value] of Object.entries(toSend)) {
            data.append(key, value);
        }
        dispatch(handleUserform(data, { navigate: navigate }));
    };

    const ref: any = useRef();
    const userInfo = {
        f_name: "",
        l_name: "",
        country: "",
        phone: "",
        countrycode: "",
    };
    const validationSchema = Yup.object({
        f_name: Yup.string().required("Please enter your first name!"),
        l_name: Yup.string().trim().required("Please enter your last name!"),
        country: Yup.string().required("Please select your country!"),
        phone: Yup.string().required("Please enter your phone number!"),
        countrycode: Yup.string().required("Please select your country code!"),
    });
    return (
        <div className="form_wrapper about_user">
            <Link to="/">
                <div className="side_logo">
                    <img src="/Images/logoII.png" alt="logo" />
                </div>
            </Link>
            <div className="form_container">
                <h3 className="form_heading">First, tell us about yourself</h3>
                <p className="about-subheading">
                    Welcome to ORDR! To set up your account, please complete the
                    required field
                </p>
                <Formik
                    innerRef={ref}
                    initialValues={userInfo}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                    }) => {
                        const { f_name, l_name, country, countrycode, phone } =
                            values;
                        return (
                            <form
                                className="signup_form"
                                onSubmit={handleSubmit}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        handleSubmit();
                                    }
                                }}
                            >
                                <div className="form_inputfield">
                                    <input
                                        type="text"
                                        placeholder="First Name"
                                        name="f_name"
                                        onChange={handleChange("f_name")}
                                        value={f_name}
                                    />
                                    {touched.f_name && errors.f_name && (
                                        <p className="errmsg">
                                            {errors.f_name}
                                        </p>
                                    )}
                                </div>
                                <div className="form_inputfield">
                                    <input
                                        type="text"
                                        placeholder="Last Name"
                                        name="l_name"
                                        onChange={handleChange("l_name")}
                                        value={l_name}
                                    />
                                    {touched.l_name && errors.l_name && (
                                        <p className="errmsg">
                                            {errors.l_name}
                                        </p>
                                    )}
                                </div>

                                <div className="form_inputfield">
                                    <Country
                                        mode="country_name"
                                        handleChange={handleChange("country")}
                                    />
                                    {touched.country && errors.country && (
                                        <p className="errmsg">
                                            {errors.country}
                                        </p>
                                    )}
                                </div>
                                <div className="form_inputfield ">
                                    <div className="signup_smselect">
                                        <Country
                                            mode="country_num"
                                            handleChange={handleChange(
                                                "countrycode"
                                            )}
                                        />
                                    </div>
                                    <input
                                        type="number"
                                        placeholder="PhoneNumber"
                                        className="related_inputfield"
                                        name="phone"
                                        onChange={handleChange("phone")}
                                        value={phone}
                                    />
                                    {touched.countrycode &&
                                        errors.countrycode && (
                                            <p className="errmsg">
                                                {errors.countrycode}
                                            </p>
                                        )}
                                    {touched.phone && errors.phone && (
                                        <p className="errmsg">{errors.phone}</p>
                                    )}
                                </div>
                                <div className="signup_createbtn">
                                    <AppButton
                                        btnType="lgbutton"
                                        htmlType="submit"
                                    >
                                        Create
                                        {loader && (
                                            <Loader classType="loader-nspinner" />
                                        )}
                                    </AppButton>
                                </div>
                            </form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
};

export default AboutUser;
