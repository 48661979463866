import { Link } from "react-router-dom";
import AppButton from "../AppButton";

type LandingCardProps = {
    title?: string;
    paragraph?: string;
    subTitle?: string;
    PackageList?: Array<contentListProps>;
    src?: any;
};
type contentListProps = {
    content: string;
};
const LandingPackage = (props: LandingCardProps) => {
    const { title, paragraph, subTitle, PackageList, src } = props;

    return (
        <div className="landing-package padding">
            <h4>{title}</h4>
            <p>{paragraph}</p>
            <div className="starting_at">Starting at</div>
            <h2 className="landingpackage_subheading">{subTitle}</h2>
            <div className="packagelist_wrapper">
                {PackageList?.map((data, index) => {
                    return (
                        <>
                            <div className="packagelist_content" key={index}>
                                <div className="card_checkbox">
                                    <img
                                        src="/Images/tick.svg"
                                        alt="checbox-icon"
                                    />
                                </div>
                                <div>{data.content}</div>
                            </div>
                        </>
                    );
                })}
                <a href={src}>
                    <div className="landingpackage_btn">
                        <AppButton btnType="lgbutton">Shop Now</AppButton>
                    </div>
                </a>
            </div>
        </div>
    );
};

export default LandingPackage;
