import axios from "axios";
import { Dispatch } from "redux";
import api from ".";
import { API_BASE_URL } from "../../constant";
import { getReceipt } from "../Action/receipt.action";

export const getOrderReceipt = (id: string) => (dispatch: Dispatch) => {
    axios({
        url: `${API_BASE_URL}/download-reciept/${id}`,
        method: "GET",
        responseType: "blob",
    })
        .then((response: any) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Receipt.pdf");
            document.body.appendChild(link);
            link.click();
        })
        .catch((err) => {});
};
