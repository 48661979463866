import { Dispatch } from "redux";
import api from ".";
import { getDetailProduct } from "../Action/detailProduct.action";
import { displayLoader } from "../Action/loader.action";

export const getAllDetailProduct = (id: string) => (dispatch: Dispatch) => {
    api.get(`/products/${id}`)
        .then((response) => {
            dispatch(displayLoader(false));
            dispatch(getDetailProduct(response.data.data));
        })
        .catch((err) => {
            dispatch(displayLoader(false));
        });
};
