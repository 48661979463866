import { loaderAction, BUSINESS_TYPE } from "../../const";
import { BusinessType } from "../../Type/businessType.type";

export interface BusinessTypeReducer {
    bType: BusinessType;
    loader: boolean;
}
const inititalState: BusinessTypeReducer = {
    bType: {} as BusinessType,
    loader: true,
};

const businessTypeReducer = (state = inititalState, { type, payload }: any) => {
    switch (type) {
        case BUSINESS_TYPE:
            return {
                ...state,
                bType: payload,
            };
        case loaderAction.GET_LOADER:
            return {
                ...state,
                loader: payload,
            };
        default:
            return state;
    }
};
export default businessTypeReducer;
