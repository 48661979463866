import React from 'react'
import LandingFooter from '../Landing/LandingFooter'
import LandingNav from '../Landing/LandingNav'
import ReadyToStart from '../Landing/ReadyToStart'

const Privacy = () => {
  return (

    <>
    <LandingNav/>
    <div className="terms_privacy privacy_wrapper">
    <h2>Privacy Policy</h2>
    <ol>
        <li>Introduction</li>
        <div>
            <p>
                When signing up for or using ORDR you are
                entrusting ORDR with the protection and
                sensitivity of your information, and that of
                your customers. This is a responsibility we take
                very seriously. This document is intended to
                outline what we collect, how we store it, what
                we do with it, and what your rights are as it
                relates to that data.
            </p>
        </div>
        <li>Scope of Privacy Policies</li>
        <div>
            <p>
                This privacy policy is intended to apply to
                individuals or entities who use ORDR's websites,
                mobile applications, products and services
                (collectively, "Services"). For the purposes of
                this Policy, "User", as the term is used here,
                refers to an individual or an entity that uses
                our Services, referred to as "User" or "Users".
            </p>
            <p>
                Any reference to Personal Data means any
                information stored by ORDR that could lead to an
                individual person being identified either
                directly or indirectly. This may include but is
                not limited to name, email address, phone
                number, street or postal address, IP address
                etc. By registering for ORDR you acknowledge the
                collection, transfer, storage, processing,
                disclosure and other uses of your information as
                outlined in this privacy policy
            </p>
            <p>
                User Information, being personal information
                relating to our Users and (where applicable)
                their businesses. We collect this information so
                that we can provide our Services to those Users.
                For the purposes of GDPR in the European Union,
                we are the 'data controller' of this User
                Information and we collect and store it for the
                purposes of providing our Services to Users and
                maintaining records and contact details relating
                to those Services and Users. This policy sets
                out how ORDR process your information as a User.
            </p>
            <p>
                Customer Information, being personal information
                relating to individuals with whom our Users
                interact (such as customers of their
                businesses). Users of our Services are solely
                responsible for establishing policies for and
                ensuring compliance with all applicable laws and
                regulations, as well as all privacy policies,
                agreements or other obligations, relating to the
                collection of personal information in connection
                with the use of our Services by individuals with
                whom our Users interact. We collect information
                under the direction of our Users, and have no
                direct relationship with individuals whose
                personal information we process in connection
                with the use of our Services. If you are a User
                providing information (including personal
                information) about someone else, you must have
                the legal ability to do so and, if necessary,
                inform them about how their personal information
                will be used (as described in this Privacy
                Policy). If you are an individual who interacts
                with a User using our Services – for instance if
                you're an employee or customer of a business –
                that User is the controller of your information
                and you should contact them directly (e.g. – the
                owner or manager of the business) – for
                assistance with any requests or questions
                relating to your personal information.
            </p>
        </div>
        <li>What do we use your information for?</li>
        <div>
            <p>
                Any of the information we collect from you may
                be used in one of the following ways:
            </p>
            <ul>
                <li>
                    To personalize your experience (your
                    information helps us to better respond to
                    your individual needs)
                </li>
                <li>
                    To improve our website (we continually
                    strive to improve our website offerings
                    based on the information and feedback we
                    receive from you)
                </li>
                <li>
                    To improve customer service (your
                    information helps us to more effectively
                    respond to your customer service requests
                    and support needs)
                </li>
                <li>To process transactions</li>
                <li>
                    Your information, whether public or private,
                    will not be sold, exchanged, transferred, or
                    given to any other company for any reason
                    whatsoever, without your consent, other than
                    for the express purpose of delivering the
                    purchased product or service requested.
                </li>
                <li>To send periodic emails</li>
                <li>
                    The email address you provide may be used to
                    send you information, respond to inquiries,
                    and/or other requests or questions.
                </li>
            </ul>
        </div>
        <li>How do we protect your information?</li>
        <div>
            <p>
                We implement a variety of security measures to
                maintain the safety of your personal information
                when you place an order or enter, submit, or
                access your personal information
            </p>
            <p>
                All supplied sensitive/credit information is
                transmitted via Secure Socket Layer (SSL)
                technology and then encrypted into our Payment
                gateway providers database only to be accessible
                by those authorized with special access rights
                to such systems, and are required to keep the
                information confidential.
            </p>
            <li> Do we use cookies?</li>
            <p>
                Yes (Cookies are small files that a site or its
                service provider transfers to your computers
                hard drive through your Web browser (if you
                allow) that enables the sites or service
                providers systems to recognize your browser and
                capture and remember certain information
            </p>
            <p>
                We use cookies to help us remember that you've
                logged into ORDR, understand and save your
                preferences for future visits and compile
                aggregate data about site traffic and site
                interaction so that we can offer better site
                experiences and tools in the future.
            </p>
            <p>
                As you browse myordr.com, advertising cookies
                will be placed on your computer so that we can
                understand what you are interested in. Our
                display advertising partners, then enable us to
                present you with retargeting advertising on
                other sites based on your previous interaction
                with myordr.com. The techniques our partners
                employ do not collect personal information such
                as your name, email address, postal address or
                telephone number.
            </p>
            <p>
                See ORDR's Cookie Policy <a href="url">here</a>
            </p>
        </div>
        <li>Email communications</li>
        <div>
            <p>
                ORDR sends billing information, product
                information, Service updates and Service
                notifications to you via email. Our emails will
                contain clear and obvious instructions
                describing how you can choose to be removed from
                any mailing list not essential to the Service.
                ORDR will remove you at your request.
            </p>
            <li>
                Do we disclose any information to outside
                parties?
            </li>
            <p>
                ORDR works with a number of third parties and
                partners who help us provide the ORDR service to
                our Users. Examples include hosting services,
                accounting services, business intelligence
                platforms, payment gateway providers, email and
                SMS partners, customer relationship management
                services, customer support platforms and more.
                In order to supply the ORDR service ORDR may
                send them Personal Data for storage or
                processing. ORDR has taken steps to ensure that
                each partner or third party involved treats the
                Personal Data in accordance with the privacy
                laws and legislation of our Users'
                jurisdictions.
            </p>
            <p>
                We may also release your information when we
                believe release is appropriate to comply with
                the law, enforce our site policies, or protect
                ours or others rights, property, or safety.
            </p>
            <p>
                Non-personally identifiable visitor information
                may be provided to other parties for marketing,
                advertising, or other uses
            </p>
            <p>
                Other than stated above, we do not sell, trade,
                or otherwise transfer to outside parties your
                personally identifiable information
            </p>
            <li>How long do we store your information?</li>
            <p>
                We will retain your information and your
                client's information for as long as necessary
                for us to provide you a service, to comply with
                our legal obligations and to fulfil any business
                need we may have.
            </p>
            <p>
                When we have no ongoing legitimate business need
                to process your personal information, we will
                either delete or anonymise it or, if this is not
                possible (for example, because your personal
                information has been stored in backup archives),
                then we will securely store your personal
                information and isolate it from any further
                processing until deletion is possible.
            </p>
        </div>
        <li>Third Party Links</li>
        <div>
            <p>
                Occasionally, at our discretion, we may include
                or offer third party products or services on our
                website. By opting to take up these third party
                products you agree to ORDR providing your
                information to these third parties and you
                acknowledge that these third party sites have
                separate and independent privacy policies. We
                therefore have no responsibility or liability
                for the content and activities of these linked
                sites. Nonetheless, we seek to protect the
                integrity of our site and welcome any feedback
                about these sites
            </p>
            <li>Access to your account</li>
            <p>
                To quickly and efficiently resolve customer
                support issues related to your account, you
                consent to us logging into your account from
                time-to-time. If you do not wish us to access
                your account, then you may notify us in writing.
            </p>
            <li>Cross-border transfer</li>
            <p>
                To bring you Services, we operate globally. In
                order to do so, your personal information may be
                transferred to, and processed in countries other
                than the country you live in, outside of your
                home country, including to Australia, New
                Zealand, United Kingdom and the United States.
                These countries may have laws different to what
                you're used to. Rest assured, where we disclose
                Personal Data to a third party in another
                country, we put safeguards in place to ensure
                your Personal Data remains protected.
            </p>
            <p>
                Specifically, ORDR hosts data with Amazon Web
                Service in the United States and in Singapore.
                This means that your personal information will
                be transferred to the US or to Singapore. The
                servers on which personal information is stored
                are kept in a controlled and secure environment.
            </p>
            <p>
                European Economic Area (EEA) users: This means
                that your information may be transferred outside
                of the EEA. Where your personal information is
                transferred outside the EEA, it will only be
                transferred to countries that have been deemed
                to provide adequate protection for EEA
                information (like New Zealand and Australia), or
                to a third party where we have approved transfer
                mechanisms in place to protect your personal
                information – i.e., by entering into the
                European Commission's Standard Contractual
                Clauses, or by ensuring the entity is Privacy
                Shield certified (for transfers to US based
                third parties).
            </p>
            <p>
                Australian users: The third parties we share
                data with do not control, and are not permitted
                to access or use your Personal Data, except for
                the limited purpose it was provided. This means
                that, for the purposes of Australian privacy
                legislation and Australian users, ORDR does not
                disclose Personal Data to third parties located
                overseas
            </p>
        </div>
        <li>Your rights </li>
        <div>
            <p>Deactivating your account</p>
            <p>
                If you wish to deactivate your ORDR account, you
                may do so by logging into your ORDR account and
                following these instructions.
            </p>
            <p>Your European Privacy Rights</p>
            <p>
                In addition to the rights listed above, if you
                are in the EEA you also have rights to:
            </p>
            <ul>
                <li>
                    Know what personal information we hold about
                    you, and to make sure it's correct and up to
                    date
                </li>
                <li>
                    Request a copy of your personal information
                    or ask us to restrict processing of or
                    delete it
                </li>
                <li>
                    Object to our continued processing of your
                    personal information and to request a
                    permanent deletion of your data
                </li>
            </ul>
            <p>
                For more information about your European Privacy
                Rights, please see our GDPR help guide.
            </p>
            <p>
                If you're not happy with how we are processing
                your personal information, you have the right to
                complain to your local information protection
                authority. Your local data protection authority
                will be able to give you more information on how
                to submit a complaint.You can request to
                exercise these rights by emailing{" "}
                <a href="url">privacy@MYORDR.com.</a> We will
                process your request within 30 days of receiving
                your request. Note that we may require proof of
                identification before we process your request.
            </p>
        </div>
        <li>Children and our Services</li>
        <div>
            <p>
                Our Services are not directed to children, and
                you may not use our Services if you are under
                the age of 18. You must also be old enough to
                consent to the processing of your Personal Data
                in your country (in some countries we may allow
                your parent or guardian to do so on your
                behalf).
            </p>
        </div>

        <li>Your consent</li>
        <div>
            <p>
                By using our site, you consent to our privacy
                policy.
            </p>
        </div>
        <li>Changes to our Privacy Policy</li>
        <div>
            <p>
                If we decide to change our privacy policy, we
                will post those changes on this page.
            </p>
        </div>
        <li>Contacting Us</li>
        <div>
            <p>
                If you have any questions regarding this privacy
                policy, email us at privacy@MYORDR.com or post
                your questions to the address below:
            </p>
            <ul>
                <li className="address_list">
                    No. 204, Dhama Thukha Kyaung Street
                </li>
                <li className="address_list">
                    The Leaf Tower, Suite 306/307{" "}
                </li>
                <li className="address_list">
                    Hlaing Township, Yangon
                </li>
                <li className="address_list">Myanmar, 10110</li>
            </ul>
        </div>
    </ol>
</div>   <ReadyToStart/>
    <LandingFooter/></>
  )
}

export default Privacy