import React from "react";

type LoaderProps = {
    classType?: "loading-spinner" | "loader-nspinner";
};
const Loader = (props: LoaderProps) => {
    const { classType } = props;
    return (
        <div className={`loader-spinner ${classType}`}></div>
        // <div className="loading-spinner listing-loader"></div>
    );
};

export default Loader;
