import React from "react";
import { Package } from "../../Helpers/Package";
import LandingPackage from "../Landing/LandingPackage";

type PlanProps = {
    title?: string;
    content?: string;
};
const PackagePlan = (props: PlanProps) => {
    const { title, content } = props;
    return (
        <>
            <div className="landingPackage_section package_plan">
                <h2 className="landing_headingII">{title}</h2>
                {content && <p className="package_content">{content}</p>}
                <div className="landingpackage_wrapper">
                    {Package?.map((packageData, index) => {
                        return (
                            <LandingPackage
                                key={index}
                                title={packageData.title}
                                paragraph={packageData.paragraph}
                                subTitle={packageData.subTitle}
                                PackageList={packageData.PackageList}
                                src={packageData.src}
                            />
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default PackagePlan;
