import { Dispatch } from "redux";
import { userForm } from "../../Action/viewadmin.action";
import { vmApi } from "..";
import { displayLoader } from "../../Action/loader.action";

export interface loginType {
    f_name?: string;
    l_name?: string;
    country: string;
    phone: string;
}

export const handleUserform =
    (data: any, props: any) => (dispatch: Dispatch) => {
        dispatch(displayLoader(true));

        const { navigate } = props;
        const { id } = data;
        vmApi
            .post(`/vAdmin/user`, data)
            .then((response: any) => {
                dispatch(displayLoader(false));
                dispatch(userForm(response));
                navigate(`/virtual-menu/about-restaurant`, {
                    replace: true,
                });
            })
            .catch((err: any) => {
                dispatch(displayLoader(false));
            });
    };
