import { loaderAction, V_SIGN_UP } from "../../const";
export interface SignupReducer {
    vsignup: any;
    loader: boolean;
}
const initialState = {
    vsignup: {},
    token: "",
    loader: false,
};
const vSignupReducer = (state = initialState, { type, payload }: any) => {
    switch (type) {
        case V_SIGN_UP:
            return {
                ...state,
                vsignup: payload,
            };
        case loaderAction.GET_LOADER:
            return {
                ...state,
                loader: payload,
            };
        default:
            return state;
    }
};
export default vSignupReducer;
