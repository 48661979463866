import React from "react";
import Footer from "../Footer";
import LandingFooter from "../Landing/LandingFooter";
import LandingNav from "../Landing/LandingNav";
import ReadyToStart from "../Landing/ReadyToStart";

const Terms = () => {
    return (
        <>
            <LandingNav/>
                <div className="terms_privacy terms_wrapper">
                    <h2>Terms of Service and Service Agreement</h2>
                    <p>
                        ORDR Limited (“ORDR”) requires users (“Customer”) of its
                        services and software (“Services”), to accept and adhere
                        to these terms and conditions (the “Agreement”). This
                        Agreement governs the purchase and use of ORDR’s
                        Services and by accessing or using any part of the
                        Services, you are agreeing to the terms and conditions
                        described below. ORDR may update this Agreement from
                        time to time and Customer will have 30 days to reject
                        the updated terms by providing written notice to ORDR.
                        If Customer continues to use or receive the Services
                        following such period, the updated Agreement will be
                        deemed accepted.
                    </p>
                    <ol>
                        <li>
                            Introduction
                            <ol>
                                <li>
                                    Rights for Use. The Services include the
                                    right to use the ORDR software programs and
                                    support services, for purposes including,
                                    but not limited to Web application (accessed
                                    on any web browser), Native iOS apps (ORDR,
                                    Consult, Stock management), PWA Android app,
                                    ORDRPay payment gateway, ORDRPay Terminals,
                                    onboarding and data import services, premium
                                    support (collectively, the “Services”). The
                                    term “ORDR Programs” refers to the software
                                    products owned by ORDR to which ORDR grants
                                    you access as part of the Services and any
                                    program updates provided as part of the
                                    Services. The term “Users” shall mean those
                                    individuals authorized by you or on your
                                    behalf who are your employees or contractors
                                    to use the Services. The term “Your Data”
                                    refers to the data provided by you that
                                    resides in your Services environment.
                                    Subject to the terms and conditions of this
                                    Agreement, Company hereby grants Customer
                                    the limited, non-exclusive,
                                    non-transferable, non-sublicenseable,
                                    limited worldwide right to access and use
                                    the ORDR Platform solely for Customer’s
                                    business purposes.
                                </li>
                                <li>
                                    Accounts; Security. Access to or use of
                                    certain portions and features of the
                                    Services may require you to create an
                                    account (“Account”). Customer states that
                                    all information provided by it is current,
                                    accurate, complete, and not misleading.
                                    Customer further states that it will
                                    maintain and update all information provided
                                    by it to ensure accuracy on a prompt, ORDR
                                    basis. Customer is entirely responsible for
                                    maintaining the confidentiality and security
                                    of its account(s), including your password.
                                    Accounts are not transferrable, excluding if
                                    Customer’s business undergoes a change of
                                    ownership or control. Customer agrees to
                                    promptly notify ORDR if Customer becomes
                                    aware or suspects any unauthorized use of
                                    its accounts, including any unauthorized
                                    access or attempted access. Customer is
                                    responsible for all activities that occur
                                    under its account(s). Further, Customer is
                                    the primary account holder and is
                                    responsible for all charges made by
                                    additional users added to the accounts. Any
                                    sharing of such data to reduce the number of
                                    licenses required or sharing account
                                    information in any way is strictly
                                    prohibited.
                                </li>
                                <li>
                                    License to Use. Subject to the terms and
                                    conditions of this Agreement, ORDR hereby
                                    grants Customer the limited, non-exclusive,
                                    non-transferable, non-sublicenseable,
                                    limited worldwide right to access and use
                                    the ORDR Platform solely for Customer’s
                                    business purposes.
                                </li>
                                <li>
                                    Restrictions on Use. In accessing or using
                                    the Services, Customer will not: (a) devise
                                    specifications from, reverse engineer,
                                    reverse compile, disassemble, or create
                                    derivative works based on the Services; (b)
                                    apply systems to extract or modify
                                    information in the Services using technology
                                    or method such as those commonly referred to
                                    as “web scraping,” “data scraping,” or
                                    “screen scraping”; (c) knowingly input or
                                    post through or to the Services any content
                                    that is illegal, threatening, harmful, lewd,
                                    offensive, or defamatory or that infringes
                                    the intellectual property rights, privacy
                                    rights or rights of publicity of others, (d)
                                    store data on the Services that is regulated
                                    by the HIPAA Privacy Rules or the PCI Data
                                    Standards (e) input or transmit through or
                                    to the Services any virus, worm, Trojan
                                    Horse, or other mechanism that could damage
                                    or impair the operation of the Services or
                                    grant unauthorized access thereto; (f) use
                                    or access the Services for purposes of
                                    monitoring the availability, performance or
                                    functionality of the Services or for any
                                    other benchmarking or competitive purposes;
                                    or (g) cause, assist, allow or permit any
                                    third party (including an end-user) to do
                                    any of the foregoing; (h) use the Services
                                    to compete with ORDR in any way; or (i)
                                    permit any third party to use or access the
                                    Services other than your direct employees or
                                    contractors who are acting on your behalf.
                                </li>
                                <li>
                                    Maintenance. Customer agrees that ORDR may
                                    make any modifications to the underlying
                                    software and Services that ORDR deem
                                    necessary without prior notification to
                                    customers All such modifications will be
                                    considered part of the Services for purposes
                                    of this Agreement.
                                </li>
                                <li>
                                    Applicable Laws. Customer’s access to and
                                    use of the Services is subject to all
                                    applicable laws and regulations (including
                                    without limitation any local laws or
                                    regulations in your country, state, city, or
                                    other governmental area, regarding online
                                    conduct and acceptable content, and
                                    including all applicable laws regarding the
                                    transmission of technical data exported from
                                    the country in which this website resides or
                                    the country in which you reside). Customer
                                    may not use the Services or any information
                                    data or Customer Content in violation of or
                                    to violate any law, rule, or regulation.
                                    Ensuring Customer’s use of the Services are
                                    compliant with applicable laws is the
                                    responsibility of Customer. Customer also
                                    agrees that it will comply with the relevant
                                    portions of the Payment Card Industry (PCI)
                                    Data Security Standard (“PCI”) when
                                    collecting, accessing, storing, processing
                                    or otherwise using credit card information
                                    from your end users.
                                </li>
                                <li>
                                    Suspension of Services. ORDR has the right
                                    to immediately suspend the Services (a) in
                                    order to prevent damage to or degradation of
                                    the Services or unauthorized or
                                    non-compliant use or (b) for operational
                                    reasons such as repair, maintenance, or
                                    improvement or because of any emergency, or
                                    (c) if, following notice from ORDR, Customer
                                    has failed to pay any amounts due and owing.
                                    In the case of (a) or (b) ORDR will give
                                    Customer prior notice if reasonable and will
                                    ensure that the Services are restored as
                                    soon as possible after the event given rise
                                    to suspension has been resolved to ORDR’s
                                    reasonable satisfaction.
                                </li>
                            </ol>
                        </li>
                        <li>
                            Data License
                            <ol>
                                <li>
                                    Customer Content. As between ORDR and
                                    Customer, all title and intellectual
                                    property rights in and to all electronic
                                    data or information submitted to and stored
                                    in the Services that is owned by Customer
                                    (“Customer Content”) is owned by Customer.
                                    Customer acknowledges and agrees that in
                                    connection with the provision of the
                                    Services, ORDR may store and maintain
                                    Customer Content for a period of time
                                    consistent with ORDR’s standard business
                                    processes for the Services and Privacy
                                    Policy. Following expiration or termination
                                    of the Agreement or a Customer account, if
                                    applicable, ORDR may deactivate the
                                    applicable Customer account(s) and delete
                                    any data therein. Customer grants ORDR the
                                    right to host, use, process, display and
                                    transmit Customer Content to provide the
                                    Services pursuant to and in accordance with
                                    this Agreement. Customer has sole
                                    responsibility for the accuracy, quality,
                                    integrity, legality, reliability, and
                                    appropriateness of Customer Content, and for
                                    obtaining all rights related to Customer
                                    Content required by ORDR to perform the
                                    Services
                                </li>
                                <li>
                                    Aggregated Data. Customer agrees that,
                                    subject to ORDR’s confidentiality
                                    obligations in this Agreement, ORDR may (a)
                                    capture data regarding the use of the
                                    Services by Customer and its end users, (b)
                                    collect metrics and data included in the
                                    Customer Content, and (c) aggregate and
                                    analyze any metrics and data collected
                                    pursuant to subsections (a) and/or (b) of
                                    this sentence (collectively, the “Aggregated
                                    Data”). Customer agrees that ORDR may use,
                                    reproduce, distribute, and prepare
                                    derivative works from the Customer Content,
                                    solely as incorporated into Aggregated Data,
                                    provided that under no circumstances will
                                    ORDR use the Aggregated Data in a way that
                                    identifies Customer or its users as the
                                    source of the data.
                                </li>
                            </ol>
                        </li>
                        <li> Third Party Services
                        <p>
                            Except as otherwise agreed by in writing, Customer
                            is prohibited from linking to, modifying, or
                            embedding the Services, or any portion of the
                            Services, ORDR reserves the right to disable any
                            unauthorized links, scripts or frames targeting the
                            Services. ORDR will not be responsible and expressly
                            disclaims any liability for any third-party services
                            that Customer may use or connect to through the
                            Services. If Customer activates any connections in
                            the Services to third-party services or APIs,
                            Customer thereby authorizes ORDR to send and receive
                            Customer Content with any such activated third-party
                            service and represents and warrants to ORDR that
                            Customer has all appropriate right and title to
                            grant such authorization.
                        </p>
                        </li>
                        <li>
                            Intellectual Property
                            <ol>
                                <li>
                                    Proprietary Rights. ORDR’s intellectual
                                    property, including without limitation the
                                    Services, its trademarks and copyrights and
                                    excluding any Customer Content contained
                                    therein, and any modification thereof, are
                                    and will remain the exclusive property of
                                    ORDR and its licensors. No additional
                                    licenses or rights are granted to Customer
                                    except for the limited rights expressly
                                    granted in this Agreement.
                                </li>
                                <li>
                                    Feedback. Customer agrees that advice,
                                    feedback, criticism, or comments provided to
                                    ORDR related to the Services are given to
                                    ORDR and may be used by ORDR freely and
                                    without restriction and will not enable
                                    Customer to claim any interest, ownership,
                                    or royalty in ORDR’s intellectual property.
                                </li>
                            </ol>
                        </li>
                        <li>
                            Payment
                            <ol>
                                <li>
                                    <p>
                                        You agree to pay ORDR the subscriptions
                                        fees, and any other applicable fees, for
                                        the subscription you selected as
                                        specified on the ORDR website during the
                                        registration process. All subscriptions
                                        fees will be automatically billed to
                                        your payment card or billed manually via
                                        bank depending on customer’s preference.
                                        Customer hereby authorizes all such
                                        charges. Fees are charged in advance on
                                        a monthly basis. In the event you fail
                                        to pay any amount when due, ORDR may
                                        suspend or terminate this Agreement and
                                        your access to the Services. If the fees
                                        are not received by ORDR according to
                                        these terms, ORDR will provide e-mail
                                        notice to Customer regarding this
                                        situation and will extend a grace period
                                        of not more than twenty (20) days
                                        (beyond original due date) for all
                                        overdue payments. If overdue payments
                                        are not received within said grace
                                        period, in addition to all other
                                        remedies that may be available: (a)
                                        Customer shall reimburse ORDR for all
                                        reasonable costs incurred by ORDR in
                                        collecting any late payments or
                                        interest, including attorneys’ fees,
                                        court costs and collection agency fees;
                                        (b) ORDR may disable, or withhold,
                                        suspend or revoke its grant of a rights,
                                        or performance of Services relating to
                                        until all past due amounts and interest
                                        thereon have been paid, without
                                        incurring any obligation or liability to
                                        Customer or any other person by reason
                                        of any such action; and (c) ORDR may
                                        terminate this Agreement
                                    </p>
                                    <p>
                                        All service Fees are non-cancelable and
                                        non-refundable for the entire Term.
                                        There will be no refunds or credits for
                                        partial use of the Services,
                                        upgrade/downgrade refunds, nor refunds
                                        for months unused with an active
                                        account. Purchased SMS credits will not
                                        be refunded, including as a result of
                                        cancellation. Downgrading your Services
                                        may cause the loss of content, features,
                                        or capacity of your Account. ORDR does
                                        not accept any liability for such loss.
                                        Customer is responsible for all Fees of
                                        any renewals relating to your Account
                                        until such time as your Account or these
                                        Terms are terminated as specified
                                        herein.
                                    </p>
                                    <p>
                                        You are solely responsible for any
                                        liability resulting from your handling
                                        of credit card information. You agree
                                        that you will comply with PCI DSS
                                        anytime the Services are used to process
                                        credit cards. You agree to only store
                                        credit card details in the secure credit
                                        card platforms provided by ORDR (e.g.,
                                        ORDRPay, Stripe or fields explicitly
                                        stating you can enter credit card
                                        details). ORDR reserves the right to
                                        suspend or restrict your Account or your
                                        use of the Services if credit card
                                        details are detected in other areas such
                                        as booking, or your customer note
                                        fields.
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            Free Trial.
                            <ol>
                                <li>
                                    <p>
                                        ORDR makes available to you a free trial
                                        at no charge to you. These Terms apply
                                        to your use of any free trial. Any free
                                        trial is provided “As Is” with no
                                        warranties of any kind. ORDR is not
                                        responsible for any of your, or your end
                                        users’ data, including retention or
                                        return, when you or your end users’ use
                                        or access free trial. ORDR may
                                        discontinue, or suspend your use of, any
                                        free trial at any time, with or without
                                        notice and without any further
                                        obligations or liability to you.{" "}
                                    </p>
                                    <p>
                                        The free trial will begin the day the
                                        account is opened and will end at the
                                        earlier of 14 days
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            Services and Pricing Modification
                            <ol>
                                <li>
                                    <p>
                                        ORDR may modify, add, change, suspend,
                                        remove, or stop the Services, or any
                                        features or functionality, from time to
                                        time, in our sole discretion. We may
                                        choose to do so at any time without any
                                        notice to the Customer. We may also
                                        change features or impose additional
                                        limitations as we deem appropriate.
                                    </p>
                                    <p>
                                        ORDR may modify, add, change, suspend,
                                        remove, or stop the Services, or any
                                        features or functionality, from time to
                                        time, in our sole discretion. We may
                                        choose to do so at any time without any
                                        notice to the Customer. We may also
                                        change features or impose additional
                                        limitations as we deem appropriate.
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            Fair Use
                            <p>
                                You understand that ORDR uses third-party
                                vendors and hosting partners to provide the
                                necessary hardware, software, networking,
                                storage, and other technology required to run
                                the Services. ORDR reserves the right to suspend
                                or restrict your Account or your use of the
                                Services, or disable any third-party
                                integrations you have, where we believe that
                                your use is not fair or reasonable or that it
                                may cause degradation of the Services to other
                                users. This includes circumstances where your
                                use (or integration of your Account with a third
                                party) is creating a security or Services
                                availability risk for ORDR or our other
                                customers, is impacting (or may impact) the
                                stability or performance of our systems, or is
                                requiring disproportionate resource to deliver
                                (e.g., in terms of storage or processing
                                requirements or support queries).
                            </p>
                            <p>
                                Where ORDR offers a SMS messaging feature as
                                part of the Services, it is expected that you
                                will use the SMS feature in the natural course
                                of running your business only. Where your usage
                                exceeds ORDR’s anticipated fair use norms for
                                your business, ORDR may limit or suspend the SMS
                                feature. Your prolonged over usage of the SMS
                                feature may result in ORDR either terminating
                                your Account or requesting that you forecast and
                                pay for additional usage over and above
                                anticipated fair use norms. ORDR reserves the
                                right to determine fair use norms in each
                                instance and enforce this policy in its sole
                                discretion.
                            </p>
                        </li>
                        <li>
                            Term and Termination
                            <p>
                                These Terms will continue to apply to you until
                                terminated by either you or ORDR. ORDR may
                                terminate these Terms (including any additional
                                terms and conditions incorporated herein) or
                                suspend your access to the ORDR Services at any
                                time if we believe you have breached any of
                                these Terms, if we stop providing the Services
                                or any material component thereof, or as we
                                believe necessary to comply with applicable law.
                                If you or ORDR terminate these Terms, or if ORDR
                                suspends your access to the Services, you agree
                                that ORDR shall have no liability or
                                responsibility to you, and (except as expressly
                                provided in these Terms) ORDR will not refund
                                any amounts that you have already paid. You may
                                terminate these Terms at any time (by completing
                                the cancellation process in the app), in which
                                case you may not continue accessing or using the
                                Services. All of your rights granted under these
                                Terms will immediately come to an end; and all
                                of your data and content may be deleted from our
                                systems (on request).
                            </p>
                            <p>
                                We provide no guarantee that your content can be
                                recovered once your Account is cancelled. We are
                                not liable for any loss or damage following, or
                                as a result of, cancellation of your account,
                                and it is your responsibility to ensure that any
                                content or data which you require is backed-up
                                or replicated before cancellation
                            </p>
                        </li>
                        <li>
                            {" "}
                            Confidential Information
                            <ol>
                                <li>
                                    Confidential Information. “Confidential
                                    Information” means any information disclosed
                                    by one party to the other whether orally or
                                    in writing that is designated as
                                    confidential or that reasonably should be
                                    understood by the receiving party to be
                                    confidential, notwithstanding the failure of
                                    the disclosing party to designate it as
                                    such. Confidential Information may include
                                    information that is proprietary to a third
                                    party and is disclosed by one party to
                                    another pursuant to this Agreement. The
                                    Services, all features, and functions
                                    thereof and related pricing and product
                                    plans will be the Confidential Information
                                    of ORDR.
                                </li>
                                <li>
                                    Non-Disclosure. Each party agrees to
                                    maintain the confidentiality of the other
                                    party’s Confidential Information with the
                                    same security and measures it uses to
                                    protect its own Confidential Information of
                                    a similar nature (but in no event less than
                                    reasonable security and measures) and not to
                                    use such Confidential Information except as
                                    necessary to perform its obligations or
                                    exercise its rights under this Agreement.
                                    The receiving party may disclose
                                    Confidential Information of the disclosing
                                    party to those employees, officers,
                                    directors, agents, affiliates, consultants,
                                    users, and suppliers who need to know such
                                    Confidential Information for the purpose of
                                    carrying out the activities contemplated by
                                    this Agreement and who have agreed to
                                    confidentiality provisions that are no less
                                    restrictive than the requirements herein.
                                    Such party will be responsible for any
                                    improper use or disclosure of the disclosing
                                    party’s Confidential Information by any such
                                    parties. Except as expressly permitted by
                                    this Section, the receiving party will not
                                    disclose or facilitate the disclosure of
                                    Confidential Information of the disclosing
                                    party to any third party. The restrictions
                                    in this Section shall continue until such
                                    time as the information is covered by an
                                    exclusion set forth below.
                                </li>
                                <li>
                                    Exclusions. The receiving party will have no
                                    obligation under this Section with respect
                                    to information provided by the disclosing
                                    party that: (a) is or becomes generally
                                    available to the public other than as a
                                    result of a breach of this Agreement by the
                                    receiving party, (b) is or becomes available
                                    to the receiving party from a source other
                                    than the disclosing party, provided that
                                    such source is not known to the receiving
                                    party to be bound by an obligation of
                                    confidentiality to the disclosing party with
                                    respect to such information, (c) was in the
                                    receiving party’s possession prior to
                                    disclosure by the disclosing party, or (d)
                                    is independently developed by the receiving
                                    party without reference to the Confidential
                                    Information. Further either party may
                                    disclose Confidential Information (i) as
                                    required by any court or other governmental
                                    body or as otherwise required by law, or
                                    (ii) as necessary for the enforcement of
                                    this Agreement or its rights hereunder.
                                </li>
                            </ol>
                        </li>

                        <li>
                            {" "}
                            Disclaimers
                            <p>
                                ORDR DOES NOT WARRANT THAT THE SERVICES WILL BE
                                PERFORMED ERROR-FREE OR UNINTERRUPTED, THAT ORDR
                                WILL CORRECT ALL ERRORS OR THAT THE SERVICES
                                WILL MEET CUSTOMER’S REQUIREMENTS OR
                                EXPECTATIONS. ORDR IS NOT RESPONSIBLE FOR ANY
                                ISSUES RELATED TO THE PERFORMANCE, OPERATIONS OR
                                SECURITY OF THE SERVICES THAT ARISE FROM
                                CUSTOMER CONTENT OR THIRD-PARTY APPLICATIONS OR
                                SERVICES PROVIDED BY THIRD PARTIES. ORDR
                                EXPRESSLY DISCLAIMS (TO THE GREATEST EXTENT
                                PERMISSIBLE UNDER APPLICABLE LAW) ALL OTHER
                                WARRANTIES EXPRESS, IMPLIED, STATUTORY OR
                                OTHERWISE, RELATING TO THE SUBJECT MATTER OF
                                THIS AGREEMENT, INCLUDING WITHOUT LIMITATION,
                                ANY WARRANTIES OF MERCHANTABILITY, TITLE, OR
                                FITNESS FOR A PARTICULAR PURPOSE.
                            </p>
                        </li>
                        <li>
                            Limitation of Liability
                            <p>
                                IN NO EVENT WILL ORDR OR ITS AFFILIATES BE
                                LIABLE FOR ANY INDIRECT, CONSEQUENTIAL,
                                INCIDENTAL, SPECIAL, PUNITIVE, OR EXEMPLARY
                                DAMAGES, OF ANY KIND OR NATURE ARISING OUT OF
                                THIS AGREEMENT OR THE SERVICES, INCLUDING
                                WITHOUT LIMITATION, ANY COST TO COVER
                                PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES
                                (WHICH THE PARTIES AGREE WILL NOT BE CONSIDERED
                                DIRECT DAMAGES), OR ANY LOSS OF REVENUE,
                                PROFITS, SALES, DATA, DATA USE, GOOD WILL, OR
                                REPUTATION. ORDR’S MAXIMUM LIABILITY ARISING OUT
                                OF OR RELATED TO THE SERVICES OR THIS AGREEMENT
                                WILL BE LIMITED TO THE AMOUNT OF FEES CUSTOMER
                                HAS PAID TO ORDR IN THE 1 MONTH PRIOR TO THE
                                EVENT(S) GIVING RISE TO SUCH LIABILITY. THE
                                LIMITATIONS SET FORTH IN THIS SECTION APPLY
                                REGARDLESS OF THE LEGAL THEORY ON WHICH A CLAIM
                                IS BROUGHT, EVEN IF ORDR HAS BEEN NOTIFIED OF
                                THE POSSIBILITY OF DAMAGE OR IF SUCH DAMAGE
                                COULD HAVE BEEN REASONABLY FORESEEN AND
                                NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE
                                OF ANY EXCLUSIVE REMEDY PROVIDED IN THIS
                                AGREEMENT.{" "}
                            </p>
                        </li>
                        <li>
                            Indemnification
                            <p>
                                Customer agrees to defend and indemnify ORDR and
                                its affiliates from and against any legal
                                action, demand, suit, or proceeding brought
                                against ORDR or its affiliates by a third party
                                arising out of or related to the Customer
                                Content or Customer’s use of the Services.
                            </p>
                        </li>
                        <li>
                            Publicity
                            <p>
                                Customer hereby consents to ORDR identifying
                                Customer as a customer by name and logo in
                                ORDR’s promotional materials, subject to
                                Customer’s right to revoke such consent in
                                writing at any time. Upon such revocation, ORDR
                                will have 30 days to process Customer’s request
                            </p>
                        </li>
                        <li>
                            Assignment
                            <p>
                                Customer may not assign or transfer this
                                Agreement or any of its rights or obligations
                                hereunder in whole or in part without the prior
                                written consent of ORDR. Subject to the
                                foregoing, this Agreement will inure to the
                                benefit of, be binding upon, and be enforceable
                                against, each of the parties hereto and their
                                respective successors and assigns
                            </p>
                        </li>
                        <li>
                            Notice
                            <p>
                                ORDR will send notices to one or more contact(s)
                                on file for Customer. Notices from ORDR, other
                                than for a breach of this Agreement may be
                                provided within the Services.
                            </p>
                        </li>
                        <li>
                            Attorney’s Fee
                            <p>
                                In the event any proceeding or lawsuit is
                                brought in connection with this Agreement, the
                                prevailing party in such proceeding will be
                                entitled to receive its reasonable costs, expert
                                witness, and attorneys’ fees.
                            </p>
                        </li>
                        <li>
                            Relationship of the Parties
                            <p>
                                This Agreement does not create any joint
                                venture, partnership, agency, or employment
                                relationship between the parties.
                            </p>
                        </li>
                        <li>
                            No Third-Party Beneficiaries
                            <p>
                                This Agreement is being entered into for the
                                sole benefit of the parties hereto, and nothing
                                herein, express, or implied, is intended to or
                                will confer upon any other person or entity any
                                legal or equitable right, benefit, or remedy of
                                any nature whatsoever.
                            </p>
                        </li>
                        <li>
                            Equitable{" "}
                            <p>
                                Each party acknowledges and agrees that (a) a
                                breach or threatened breach by such party may
                                give rise to irreparable harm to the other party
                                for which monetary damages may not be an
                                adequate remedy; and (b) if a breach or
                                threatened breach by such party occurs, the
                                other party will in addition to any and all
                                other rights and remedies that may be available
                                to such other party at law, at equity or
                                otherwise in respect of such breach, be entitled
                                to seek equitable relief that may be available
                                from a court of competent jurisdiction, without
                                any requirement to post a bond or other
                                security.
                            </p>
                        </li>
                        <li>
                            Force Majeure
                            <p>
                                Neither party will be liable under this
                                Agreement for any failure or delay in the
                                performance of its obligations (except for the
                                payment of money) on account of strikes,
                                shortages, riots, insurrections, fires, flood,
                                storm, explosions, acts of God, war,
                                governmental action, labor conditions,
                                earthquakes, material shortages, or any other
                                cause that is beyond the reasonable control of
                                such party.
                            </p>
                        </li>
                        <li>
                            Limitation of Claim
                            <p>
                                No legal proceedings, regardless of form,
                                arising under or relating to this Agreement may
                                be brought by Customer more than six months
                                after it first have actual knowledge of the
                                facts giving rise to the cause of action.
                            </p>
                        </li>
                        <li>
                            Governing Law, Jurisdiction and Venue
                            <p>
                                This Agreement is governed by and will be
                                construed in accordance with the laws of
                                Australia and each party irrevocably submits to
                                the exclusive jurisdiction of the courts of
                                Australia.
                            </p>
                        </li>
                        <li>
                            Severability, Waiver and Amendment
                            <p>
                                If any provision of this Agreement is held by a
                                court of competent jurisdiction to be
                                unenforceable or invalid, such provision will be
                                changed and interpreted as to best accomplish
                                the objectives of the original provision to the
                                fullest extent permitted by law, and the
                                remaining provisions will remain in full force
                                and effect. No waiver of any term or right in
                                this Agreement will be effective unless made in
                                writing and signed by an authorized
                                representative of the waiving party. Any waiver
                                or failure to enforce any provision of this
                                Agreement will not be deemed a waiver of future
                                enforcement of that or any other provision.
                                Except to the extent otherwise expressly
                                provided in this Agreement, this Agreement may
                                only be amended in writing signed by both
                                parties hereto.
                            </p>
                        </li>
                        <li>
                            Language of this Agreement
                            <p>
                                If this agreement is translated into any
                                language other than English, the English
                                language text shall prevail. Versions of this
                                AGREEMENT in languages other than the PREVAILING
                                LANGAUGE are provided for convenience only.
                            </p>
                        </li>
                    </ol>
                </div>
                <ReadyToStart/>
    <LandingFooter/>
        </>
    );
};

export default Terms;
