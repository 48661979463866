import { Link } from "react-router-dom";
import AppButton from "../AppButton";

const Verify = () => {
    return (
        <div className="form_wrapper">
            <Link to="/">
                <div className="side_logo">
                    <img src="/Images/logoII.png" alt="logo" />
                </div>
            </Link>
            <div className="form_container">
                <h1 className="signup_heading verify_content">
                    Your account has been verfied
                </h1>
                <Link to="/virtual-menu/login" className="signup_btn">
                    <AppButton btnType="lgbutton">
                        Sign In To Continue
                    </AppButton>
                </Link>
            </div>
        </div>
    );
};

export default Verify;
