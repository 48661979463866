import AppButton from "../AppButton";

const ReadyToStart = () => {
    return (
        <div className="readytostart_wrapper">
            <div className="ready-to-start container_wrap">
                <div className="readytostart_left">
                    <h2 className="landing_headingII">Ready to get started?</h2>
                    <p>
                        Talk to a restaurant expert today and learn how Toast
                        can help your business.
                    </p>
                </div>
                <div className="readytostart_right">
                    <AppButton btnType="smbutton">Get a Demo</AppButton>
                </div>
            </div>
        </div>
    );
};

export default ReadyToStart;
