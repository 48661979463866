export const BusinessCategory = [
    {
        name: "Restaurant",
        type: "1",
        imgscr: "/Images/restroo.svg",
        subCategories: [
            { name: " Quick Service" },
            { name: " Full Service" },
            { name: " Fast Casual" },
            { name: "Bar and Breweries" },
            { name: "Ghost Kitchen" },
            { name: "Coffee Shop" },
            { name: "Food Court" },
            { name: "Others" },
        ],
    },
    {
        name: "Hotel",
        type: "2",

        imgscr: "/Images/hotel.svg",
        subCategories: [
            { name: " Hotel" },
            { name: " Resort" },
            { name: " Service Appartment" },
            { name: "Bed and Breakfast" },
            { name: "Others" },
        ],
    },
    {
        name: "Entertainment Venue",
        type: "3",

        imgscr: "/Images/entertain.svg",
        subCategories: [
            { name: " Cinema" },
            { name: " Night Club" },
            { name: " Fast Casual" },
            { name: "Karaoke" },
            { name: "Festivals" },
            { name: "Others" },
        ],
    },
    {
        name: "Sports Venue",
        type: "4",

        imgscr: "/Images/sport.svg",
        subCategories: [
            { name: "Golf Course" },
            { name: "Driving Range" },
            { name: "Sports Center" },
            { name: "Bowling" },
            { name: "Others" },
        ],
    },
];
