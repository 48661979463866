import { Dispatch } from "redux";
import { signupForm, loginWithGoogle } from "../../Action/viewadmin.action";
import { notification } from "antd";
import { vmApi } from "..";
import { displayLoader } from "../../Action/loader.action";
import { string } from "yup";
import Cookies from "cookies-ts";

export interface signupType {
    email?: string;
    password?: string;
}
export interface GoogleLoginType {
    email: string;
    name: string;
    google_id: string;
}
export const handleSignupform =
    (data: any, props: any, show: any) => (dispatch: Dispatch) => {
        dispatch(displayLoader(true));
        const { navigate } = props;

        const key = "updatable";
        vmApi
            .post(`/vAdmin`, data)
            .then((response: any) => {
                dispatch(signupForm(response));
                dispatch(displayLoader(false));
                navigate(`/virtual-menu/verify-email`, {
                    replace: true,
                });
            })
            .catch((err: any) => {
                dispatch(signupForm(err));
                dispatch(displayLoader(false));
                setTimeout(() => {
                    notification.open({
                        key,
                        message: err.response.data.message,
                    });
                }, 1000);
            });
    };

const cookies = new Cookies();
export const LOGINWITHGOOGLE =
    (data: GoogleLoginType, props: any, show: any) => (dispatch: Dispatch) => {
        dispatch(displayLoader(true));
        const { navigate } = props;
        const key = "updatable";
        vmApi
            .post(`/vAdmin/google-login`, data)
            .then((response: any) => {
                cookies.set("token", response?.data?.token);
                dispatch(loginWithGoogle(response?.data));
                dispatch(displayLoader(false));
                show &&
                    setTimeout(() => {
                        notification.open({
                            key,
                            message: response.data.msg,
                            duration: 12,
                        });
                    }, 1);
                navigate(`/virtual-menu/about-user`, {
                    replace: true,
                });
            })
            .catch((err: any) => {
                dispatch(loginWithGoogle(err));
                dispatch(displayLoader(false));
                setTimeout(() => {
                    notification.open({
                        key,
                        message: err.response.data.msg,
                    });
                }, 1);
            });
    };
