import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getAllCategories } from "../../store/api/category";
import { StateType } from "../../store/Reducer/index.reducer";
import Footer from "../Footer";
import Navbar from "../Navbar";
import { Select } from "antd";
import Drawer from "../Drawer";
import { CATIMAGE_BASE_URL, HomeIMAGE_BASE_URL } from "../../constant";
import { displayLoader } from "../../store/Action/loader.action";
import text_truncate from "../TextTruncate";
import Loader from "../Loader";

const Category = () => {
    const dispatch: any = useDispatch();
    const { currentVenue } = useParams();
    const [visible, setVisible] = useState(false);
    const [inputTableNum, setInputTableNum] = useState(0);
    const [inputName, setInputName] = useState("");
    const [name, setName] = useState("");
    const [tablenum, setTableNum] = useState(0);
    const [validation, setValidation] = useState(false);
    const [phoneNum, setPhoneNum] = useState("");

    const [validationMsgTwo, setValidationMsgTwo] = useState("");
    const [validationMsgThree, setValidationMsgThree] = useState("");

    const { categoryRes, loader } = useSelector(
        (state: StateType) => state.category
    );

    const homeData = useSelector((state: StateType) => state.profile.profile);
    const onSubmit = () => {
        // homeData?.profile?.table?.filter(
        //     (data: any) => data?.key == inputTableNum
        // )?.length || !inputName? setValidationMsgTwo("Please provide last name") :
        // setTableNum(inputTableNum)
        if (
            homeData?.profile?.table?.filter(
                (data: any) => data?.key == inputTableNum
            )?.length
        ) {
            setTableNumber(inputTableNum);
        }
        if (!inputName) {
            setValidationMsgTwo("Please provide your fullname");
            return;
        }
        if (!phoneNum) {
            setValidationMsgThree("Please enter your contact number");
        }
        {
            setTableNumber(inputTableNum);
        }
    };
    const onChangeTwo = (e: any) => {
        {
            if (!e.target.value) {
                setValidationMsgTwo("Please provide your fullname");
            } else {
                setValidationMsgTwo("");
            }
            setInputName(e.target.value);
        }
    };
    const onChangeThree = (e: any) => {
        {
            if (!e.target.value) {
                setValidationMsgThree("Please enter your contact number");
            } else {
                setValidationMsgThree("");
            }
            setPhoneNum(e.target.value);
        }
    };
    const showDrawer = () => {
        setVisible(true);
    };

    useEffect(() => {
        dispatch(displayLoader(true));
        dispatch(getAllCategories(currentVenue, device_time));
    }, [dispatch]);
    const handleClick = () => {};

    const { Option } = Select;
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
        });
    }, []);
    let table = sessionStorage.getItem("tablenum");
    let user = sessionStorage.getItem("inputName");
    let phonenum = sessionStorage.getItem("phone");

    React.useEffect(() => {
        if (table) {
            let newtable = JSON.parse(table);
            setTableNum(newtable);
        }
        if (user) {
            setInputName(user);
        }
        if (phonenum) {
            setPhoneNum(phoneNum);
        }
    }, [table, user, phonenum]);

    const setTableNumber = (tableNum: number) => {
        setInputTableNum(tableNum);
        sessionStorage.setItem("tablenum", JSON.stringify(tableNum));
        sessionStorage.setItem("inputName", inputName);
        sessionStorage.setItem("phone", JSON.stringify(phoneNum));
    };
    return (
        <>
            <Navbar showLogo showNavMenu venue={currentVenue} />
            {loader ? (
                <Loader classType="loading-spinner" />
            ) : (
                <div className="whole_wrapper">
                    <div className="category container" onClick={handleClick}>
                        <div className="category-cover">
                            <img
                                src={
                                    homeData?.profile?.banner == "/images/user/"
                                        ? "/Images/catcover.png"
                                        : `${HomeIMAGE_BASE_URL}/${homeData?.profile?.banner}`
                                }
                                alt="category-cover"
                            />
                        </div>
                        <div className="delivery_content">
                            <div className="delivery_category">Dine-in</div>
                        </div>

                        <div className="top-content ">
                            <div className="table-num ">
                                {tablenum
                                    ? `${
                                          homeData?.profile?.type == 2
                                              ? "Room"
                                              : homeData?.profile?.type == 4
                                              ? "Room"
                                              : homeData?.profile?.type == 3
                                              ? "Tee"
                                              : "Table"
                                      } ${tablenum}`
                                    : `Select ${
                                          homeData?.profile?.type == 2
                                              ? "Room"
                                              : homeData?.profile?.type == 4
                                              ? "Room"
                                              : homeData?.profile?.type == 3
                                              ? "Tee"
                                              : "Table"
                                      } Number`}
                            </div>
                            <div className="table-btn " onClick={showDrawer}>
                                {tablenum ? "Change" : "Select"}
                            </div>
                        </div>
                        <Drawer
                            visible={visible}
                            onClose={() => setVisible(false)}
                            onSubmit={onSubmit}
                            onChangeOne={(e: any) =>
                                setInputTableNum(e.target.value)
                            }
                            onChangeTwo={(e: any) => onChangeTwo(e)}
                            onChangeThree={(e: any) => onChangeThree(e)}
                            validationMsgOne={
                                !inputTableNum ||
                                homeData?.profile?.table?.filter(
                                    (data: any) => data.key == inputTableNum
                                )?.length
                                    ? ""
                                    : `Please enter correct ${
                                          homeData?.profile?.type == 2
                                              ? "room"
                                              : homeData?.profile?.type == 4
                                              ? "room"
                                              : homeData?.profile?.type == 3
                                              ? "Tee"
                                              : "table"
                                      } number!`
                            }
                            validationMsgTwo={validationMsgTwo}
                            validationMsgThree={validationMsgThree}
                            buttondisabled={
                                homeData?.profile?.type == 3
                                    ? homeData?.profile?.table?.filter(
                                          (data: any) =>
                                              data.key == inputTableNum
                                      )?.length &&
                                      inputName.length &&
                                      phoneNum
                                        ? false
                                        : true
                                    : !homeData?.profile?.table?.filter(
                                          (data: any) =>
                                              data.key == inputTableNum
                                      )?.length
                            }
                        />
                        <div className="category_container">
                            {categoryRes?.categories?.map((item, index) => {
                                return (
                                    <div className="food-items" key={index}>
                                        <Link
                                            to={`/menu/${currentVenue}/listingmenu/${item.id}/${item.restaurant_id}`}
                                        >
                                            <div className="food-itemsimg">
                                                <img
                                                    src={
                                                        item.photo
                                                            ? `${CATIMAGE_BASE_URL}/${item.photo}`
                                                            : "/Images/noimg-1.svg"
                                                    }
                                                    alt={item.photo}
                                                />
                                            </div>
                                            {item.can_order == 0 && (
                                                <div className="order_status">
                                                    <div>
                                                        Ordering not available
                                                    </div>
                                                </div>
                                            )}

                                            <h2 className="item_name">
                                                {text_truncate({
                                                    str: item.name,
                                                    length: 18,
                                                    ending: null,
                                                })}
                                            </h2>
                                        </Link>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <Footer />
                </div>
            )}
        </>
    );
};

export default Category;
const date = new Date();
export const device_time =
    date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
