import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link, useParams } from "react-router-dom";

import { BusinessCategory } from "../../Helpers/ResturantTabs";
import { getBusinessTypeApi } from "../../store/api/view-admin/businessType-register";
import { StateType } from "../../store/Reducer/index.reducer";
import AppButton from "../AppButton";
import BusinessTab from "../BusinessTab";
import Loader from "../Loader";

const AboutBusiness = (i: any) => {
    const [tab, setTab] = React.useState(null);
    const [subCategory, setSubCategory] = React.useState<any>();
    // const { type }: any = useParams();
    const dispatch: any = useDispatch();
    const loader = useSelector((state: StateType) => state.btype.loader);

    const handleClickTab = (i: any, subCategories: any, type: string) => {
        setTab(i);
        setSubCategory(subCategories);
        dispatch(getBusinessTypeApi(type));
    };

    const businesstypes = useSelector((state: StateType) => state.btype.bType);
    return (
        <>
            {loader ? (
                <Loader />
            ) : (
                <div className="aboutbusiness_container">
                    <div className="aboutbusiness_innerwidth">
                        <div className="aboutbusiness_leftcontaner">
                            <div className="ab_content">
                                <div className="form_logo">
                                    <img src="/Images/logoII.png" alt="logo" />
                                </div>
                                <h2 className="form_heading">
                                    Tell us about Business
                                </h2>
                                <p className="about-subheading">
                                    Please fillup the information required below
                                </p>
                                <h5 className="aboutbusiness_tabheading">
                                    Select one category that best describes your
                                    field:
                                </h5>
                                <form>
                                    <div className="aboutbusiness_tabswrapper">
                                        {BusinessCategory?.map((data, i) => {
                                            return (
                                                <>
                                                    <div
                                                        className={`aboutbusiness_tabs ${
                                                            tab == i
                                                                ? "tabactive"
                                                                : "tab"
                                                        }`}
                                                        key={i}
                                                        onClick={() => {
                                                            tab == i
                                                                ? handleClickTab(
                                                                      null,
                                                                      [],
                                                                      data.type
                                                                  )
                                                                : handleClickTab(
                                                                      i,
                                                                      data.subCategories,
                                                                      data.type
                                                                  );
                                                        }}
                                                    >
                                                        <img
                                                            src={data.imgscr}
                                                        />
                                                        {data.name}
                                                    </div>
                                                </>
                                            );
                                        })}
                                    </div>
                                    <BusinessTab subCategories={subCategory} />
                                </form>
                            </div>
                            <Link
                                to="/virtual-menu/plan"
                                className="aboutbusiness_btn"
                            >
                                <AppButton btnType="lgbutton">
                                    Continue
                                </AppButton>
                            </Link>
                        </div>
                        <div className="aboutbusiness_rightcontaner">
                            <img src="/Images/b-cover.png" alt="cover-img" />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default AboutBusiness;
