import { useState, useEffect } from "react";
import { Button, Drawer, Form, Input, InputNumber } from "antd";
import type { DrawerProps } from "antd/es/drawer";
import { useSelector } from "react-redux";
import { StateType } from "../../store/Reducer/index.reducer";

interface Props {
    visible?: boolean;
    onClose: () => void;
    onChangeOne: (e: any) => void;
    onChangeTwo: (e: any) => void;
    onChangeThree: (e: any) => void;
    onSubmit: () => void;
    buttondisabled?: boolean;
    validationMsgOne?: string;
    validationMsgTwo?: any;
    validationMsgThree?: any;
}

const Index = ({
    visible,
    onClose,
    onChangeOne,
    onChangeTwo,
    onChangeThree,
    onSubmit,
    buttondisabled,
    validationMsgOne,
    validationMsgTwo,
    validationMsgThree,
}: Props) => {
    const homeData = useSelector((state: StateType) => state.profile.profile);
    let inputShow =
        homeData?.profile?.type == 3 || homeData?.profile?.type == 4;
    const makeOption = () => {
        const options =
            homeData?.profile?.table?.map((data: any, index: any) => {
                return (
                    <option key={index} value={data.key}>
                        {data.key}
                    </option>
                );
            }) || [];
        return options;
    };
    const [placement, setPlacement] =
        useState<DrawerProps["placement"]>("bottom");
    const onFinish = (values: any) => {};

    const onFinishFailed = (errorInfo: any) => {};
    const on_Submit = () => {
        onSubmit();
        onClose();
    };

    return (
        <div>
            <Drawer
                title={
                    homeData?.profile?.type == 2
                        ? "Please enter your Room number"
                        : homeData?.profile?.type == 3
                        ? "Please enter your Tee number"
                        : "Where are you seated?"
                }
                placement={placement}
                width={500}
                onClose={onClose}
                visible={visible}
                className="table__drawer"
            >
                <Form
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    className="table__form"
                >
                    <Form.Item
                        label={
                            homeData?.profile?.type == 2
                                ? "Room Number"
                                : homeData?.profile?.type == 3
                                ? "Current Tee Number"
                                : homeData?.profile?.type == 4
                                ? "Room Number"
                                : "Table Number"
                        }
                        name="tablenum"
                        rules={[
                            {
                                required: true,
                                message: "Table number is required",
                            },
                        ]}
                    >
                        <Input
                            placeholder={
                                homeData?.profile?.type == 2
                                    ? "Room number"
                                    : homeData?.profile?.type == 3
                                    ? "Current Tee number"
                                    : homeData?.profile?.type == 4
                                    ? "Room Number"
                                    : "Table Number"
                            }
                            onChange={onChangeOne}
                        />
                        {validationMsgOne && (
                            <p className="drawer_validationmsg">
                                {validationMsgOne}
                            </p>
                        )}
                    </Form.Item>

                    {inputShow && (
                        <Form.Item
                            label="Full Name"
                            name="name"
                            // className={
                            //     homeData?.profile?.type == 3 ||
                            //     homeData?.profile?.type == 4
                            //         ? "name_inputfield  active"
                            //         : "name_inputfield"
                            // }
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Please provide fullname on your booking",
                                },
                            ]}
                        >
                            <Input
                                placeholder="Full Name on booking"
                                onChange={onChangeTwo}
                                name="phone"
                            />
                            {validationMsgTwo && (
                                <p className="drawer_validationmsg">
                                    {validationMsgTwo}
                                </p>
                            )}
                        </Form.Item>
                    )}
                    {inputShow && (
                        <Form.Item
                            label="Contact Number (in case can't find you)"
                            name="phone"
                            // className={
                            //     homeData?.profile?.type == 3 ||
                            //     homeData?.profile?.type == 4
                            //         ? "name_inputfield  active"
                            //         : "name_inputfield"
                            // }
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter your contact number",
                                },
                            ]}
                        >
                            <Input
                                type="number"
                                placeholder="Contact Number"
                                onChange={onChangeThree}
                            />
                            {validationMsgThree && (
                                <p className="drawer_validationmsg">
                                    {validationMsgThree}
                                </p>
                            )}
                        </Form.Item>
                    )}
                </Form>
                <Form.Item>
                    <Button
                        onClick={on_Submit}
                        htmlType="submit"
                        disabled={buttondisabled}
                        className={buttondisabled ? "disabledBtn" : ""}
                    >
                        Continue
                    </Button>
                </Form.Item>
            </Drawer>
        </div>
    );
};

export default Index;
