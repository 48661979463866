import { Collapse } from "antd";
const menuData = [
    {
        icon: "/Images/venue.svg",
        menu: "Venues",
        text: "Delight your guests and increase spending by your regulars up to 30%",
    },
    {
        icon: "/Images/foodtruck.svg",
        menu: "Food Truck",
        text: "Delight your guests and increase spending by your regulars up to 30%",
    },
    {
        icon: "/Images/event.svg",
        menu: "Events",
        text: "Delight your guests and increase spending by your regulars up to 30%",
    },
    {
        icon: "/Images/roomservice.svg",
        menu: "Room Service",
        text: "Delight your guests and increase spending by your regulars up to 30%",
    },
];
const LandingMenu = () => {
    const { Panel } = Collapse;
    const onChange = (key: string | string[]) => {};

    return (
        <div className="landingMenu_wrapper">
            <img
                src="/Images/L-FoodI.png"
                className="imageicon imageiconI"
                alt="bread-img"
            />
            <img
                src="/Images/L-FoodII.png"
                className="imageicon imageiconII"
                alt="bread-img"
            />
            <h2 className="landing_headingII">A Menu For Everybody</h2>
            <div className="landingmenu_container">
                <div className="landingmenu_left">
                    <img src="/Images/L-image1.png" alt="image" />
                </div>
                <div className="landingmenu_right">
                    {menuData?.map((data, index) => {
                        return (
                            <>
                                <Collapse
                                    accordion
                                    defaultActiveKey={["0"]}
                                    onChange={onChange}
                                >
                                    <Panel
                                        extra={
                                            <div className="menu_iconwrapper">
                                                <img src={data?.icon} />
                                            </div>
                                        }
                                        header={data?.menu}
                                        key={index}
                                        showArrow={false}
                                    >
                                        <p>{data?.text}</p>
                                    </Panel>
                                </Collapse>
                            </>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default LandingMenu;
