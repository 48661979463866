export const Package = [
    {
        title: "Monthly",
        paragraph:
            "Ideal for small restaurants who need one or two terminals to get up and running.",
        subTitle: "$19/month",
        PackageList: [
            {
                content: "Cloud-based point of sale",
            },
            {
                content:
                    "Hardware configuration including one terminal. (Additional charges apply for subsequent devices.",
            },
            {
                content:
                    " Bring your restaurant online with Online Ordering, Toast TakeOut, and Toast Delivery Services.",
            },
            {
                content:
                    "Guest engagement tools including email marketing, gift cards, and loyalty.",
            },
        ],
        src: "https://buy.stripe.com/test_5kAbJO5tPcMDfgQaEF",
    },
    {
        title: "Yearly",
        paragraph:
            "Ideal for small restaurants who need one or two terminals to get up and running.",
        subTitle: "$209/year",
        PackageList: [
            {
                content: "Cloud-based point of sale",
            },
            {
                content:
                    "Hardware configuration including one terminal. (Additional charges apply for subsequent devices.",
            },
            {
                content:
                    " Bring your restaurant online with Online Ordering, Toast TakeOut, and Toast Delivery Services.",
            },
            {
                content:
                    "Guest engagement tools including email marketing, gift cards, and loyalty.",
            },
        ],
        src: "https://buy.stripe.com/test_00gaFK3lHaEvgkU146",
    },
    {
        title: "Custom",
        paragraph:
            "Ideal for small restaurants who need one or two terminals to get up and running.",
        subTitle: "Custom Price",
        PackageList: [
            {
                content: "Cloud-based point of sale",
            },
            {
                content:
                    "Hardware configuration including one terminal. (Additional charges apply for subsequent devices.",
            },
            {
                content:
                    " Bring your restaurant online with Online Ordering, Toast TakeOut, and Toast Delivery Services.",
            },
            {
                content:
                    "Guest engagement tools including email marketing, gift cards, and loyalty.",
            },
        ],
        src: "https://buy.stripe.com/test_bIY5lqcWh7sj0lWbIL",
    },
];
