import { Link } from "react-router-dom";
import AppButton from "../AppButton";

const VerifyEmail = () => {
    return (
        <div className="form_wrapper ">
            <Link to="/">
                <div className="side_logo">
                    <img src="/Images/logoII.png" alt="logo" />
                </div>
            </Link>
            <div className="form_container vemail_container">
                <h1 className="signup_heading verify_content">
                    Check your email inbox
                </h1>
                <div className="vemail_content">
                    We sent an email link to complete your registration
                </div>
                <div className="vemail_content">
                    Tip: Check your spam folder in case the email was
                    incorrectly identified.
                </div>
            </div>
        </div>
    );
};

export default VerifyEmail;
