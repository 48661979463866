import React from "react";
import Loader from "../Loader";

type ButtonProps = {
    className?: any;
    children?: React.ReactNode;
    btnType?:
        | "primary"
        | "secondary"
        | "tertiary"
        | "white-outlined"
        | "black-outlined"
        | "smbutton"
        | "lgbutton"
        | "black-heavyborder"
        | "inputbtn";

    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    htmlType?: "submit" | "button" | "reset";
    disabled?: boolean;
};
const AppButton = (props: ButtonProps) => {
    const { children, btnType, onClick, disabled, htmlType } = props;
    return (
        <>
            <button
                className={disabled ? "btn-disabled" : `btn btn-${btnType}`}
                onClick={onClick}
                disabled={disabled}
                type={htmlType}
            >
                {children}
            </button>
        </>
    );
};
export default AppButton;
