import React from "react";
type BusinessType = {
    subCategories?: any;
};
const BusinessTab = (props: BusinessType) => {
    const [subTab, setSubTab] = React.useState();
    const { subCategories } = props;
    const handleTab = (i: any) => {
        setSubTab(i);
    };
    return (
        <>
            {subCategories && (
                <h5 className="aboutbusiness_tabheading">
                    Pick your Restaurant category (optional):
                </h5>
            )}
            <div className="aboutbusiness_tabswrapper">
                {subCategories?.map((data: any, i: number) => {
                    return (
                        <>
                            <div
                                className={`aboutbusiness_tabs ${
                                    subTab == i ? "tabactive" : "tab"
                                }`}
                                key={i}
                                onClick={() => handleTab(i)}
                            >
                                {data.name}
                            </div>
                        </>
                    );
                })}
            </div>
        </>
    );
};

export default BusinessTab;
