import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { handleRestaurantForm } from "../../store/api/view-admin/restaurant-register";
import AppButton from "../AppButton";
import { Formik } from "formik";
import * as Yup from "yup";
import { StateType } from "../../store/Reducer/index.reducer";
import Loader from "../Loader";

const AboutCompany = () => {
    const [formData, setFormData] = useState({
        name: "",
        website: "",
        type: "",
    });
    const loader = useSelector((state: StateType) => state.vsignup.loader);
    const dispatch = useDispatch<any>();
    let navigate = useNavigate();
    const handleChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData({ ...formData, [name]: value });
    };
    const _handleSubmit = async (values: any) => {
        const toSend = {
            name: values.name,
            website: values.website,
        };
        let data = new FormData();
        for (const [key, value] of Object.entries(toSend)) {
            data.append(key, value);
        }
        dispatch(handleRestaurantForm(data, { navigate: navigate }));
    };

    const ref: any = useRef();
    const restaurantInfo = {
        name: "",
        website: "",
    };
    const validationSchema = Yup.object({
        name: Yup.string().required("Please enter your name!"),
        website: Yup.string().trim().required("Please enter your website!"),
        // type: Yup.string().required("Please select your Restaurant type!"),
    });
    return (
        <>
            <div className="form_wrapper about_company">
                <Link to="/">
                    <div className="side_logo">
                        <img src="/Images/logoII.png" alt="logo" />
                    </div>
                </Link>
                <div className="form_container">
                    <h3 className="form_heading">Tell us about your company</h3>
                    <p className="about-subheading">
                        Please fillup the information required below
                    </p>
                    <Formik
                        innerRef={ref}
                        initialValues={restaurantInfo}
                        validationSchema={validationSchema}
                        onSubmit={_handleSubmit}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleSubmit,
                        }) => {
                            const { name, website } = values;
                            return (
                                <form
                                    className="signup_form"
                                    onSubmit={handleSubmit}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            handleSubmit();
                                        }
                                    }}
                                >
                                    <div className="form_inputfield">
                                        <input
                                            type="text"
                                            placeholder="Venue Name"
                                            onChange={handleChange("name")}
                                            name="name"
                                            value={name}
                                        />
                                        {touched.name && errors.name && (
                                            <p className="errmsg">
                                                {errors.name}
                                            </p>
                                        )}
                                    </div>

                                    <div className="form_inputfield ">
                                        <div className="signup_smselect">
                                            <div className="select_input">
                                                https://
                                            </div>
                                        </div>
                                        <input
                                            type="text"
                                            placeholder="Company URL"
                                            className="related_inputfield"
                                            onChange={handleChange("website")}
                                            name="website"
                                            value={website}
                                        />
                                        {touched.website && errors.website && (
                                            <p className="errmsg">
                                                {errors.website}
                                            </p>
                                        )}
                                    </div>
                                    {/* <div className="form_inputfield ">
                                        <select
                                            placeholder="-Select your Business Type-"
                                            className="select_input"
                                            onChange={handleChange("type")}
                                            name="type"
                                            value={type}
                                        >
                                            <option
                                                value=""
                                                disabled
                                                selected
                                                hidden
                                            >
                                                - Select your Business Type -
                                            </option>

                                          
                                            <option value={1}>
                                                Restaurant
                                            </option>
                                            <option value={2}>Hotel</option>
                                            <option value={3}>
                                                Golf Course
                                            </option>
                                            <option value={4}>Spa</option>
                                        </select>
                                        {touched.type && errors.type && (
                                            <p className="errmsg">
                                                {errors.type}
                                            </p>
                                        )}
                                    </div> */}
                                    <div className="signup_createbtn">
                                        <AppButton
                                            btnType="lgbutton"
                                            htmlType="submit"
                                        >
                                            Continue
                                            {loader && (
                                                <Loader classType="loader-nspinner" />
                                            )}
                                        </AppButton>
                                    </div>
                                    <Link
                                        to="/virtual-menu/about-user"
                                        className="form_backbtn"
                                    >
                                        <AppButton btnType="black-outlined">
                                            Back
                                        </AppButton>
                                    </Link>
                                </form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </>
    );
};

export default AboutCompany;
