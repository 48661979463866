import { Dispatch } from "redux";
import { restaurantForm } from "../../Action/viewadmin.action";
import { vmApi } from "..";
import { displayLoader } from "../../Action/loader.action";

export interface restaurantType {
    name?: string;
    website?: string;
    type: string;
}

export const handleRestaurantForm =
    (data: any, props: any) => (dispatch: Dispatch) => {
        dispatch(displayLoader(true));
        const { navigate } = props;
        vmApi
            .post(`/vAdmin/restaurant`, data)
            .then((response: any) => {
                dispatch(displayLoader(false));
                dispatch(restaurantForm(response));
                navigate(`/virtual-menu/about-business`, {
                    replace: true,
                });
            })
            .catch((err: any) => {
                dispatch(displayLoader(false));
            });
    };
