import AppCard from "../../components/Landing/AppCard";
import LandingBanner from "../../components/Landing/LandingBanner";
import LandingCard from "../../components/Landing/LandingCard";
import LandingFooter from "../../components/Landing/LandingFooter";
import LandingMenu from "../../components/Landing/LandingMenu";
import LandingNav from "../../components/Landing/LandingNav";
import PackagePlan from "../../components/PackagePlan";
import ReadyToStart from "../../components/Landing/ReadyToStart";
import TrustedSection from "../../components/TrustedSection";

const index = () => {
    const CardList = [
        {
            imagescr: "../Images/L-image2.png",
            heading:
                "Combat the labor shortage and simplify operations by offering guests a better way to order and pay.",
            ContentList: [
                {
                    contentheading: "Serve more guests with fewer staff",
                    contentpara:
                        "Eliminate wait times and long lines by providing a flexible service model. Servers and guests can start and add to the tab, and items are immediately sent to the kitchen",
                },
                {
                    contentheading: "Update digital menus on the fly",
                    contentpara:
                        "Seamless POS integration means you can update your menus in real time. 86 an item from anywhere or test new pricing without wasting time or money printing new menus.",
                },
                {
                    contentheading: "Save your guests and staff time",
                    contentpara:
                        "Simplify checkout for guests and staff. With Apple Pay, guests can pay in as little as 10 seconds",
                },
            ],
        },
        {
            imagescr: "../Images/L-image3.png",
            heading:
                "Increase revenue and check size while reducing costly comps and voids.",
            ContentList: [
                {
                    contentheading: "Increase revenue",
                    contentpara:
                        "Toast Mobile Order & Pay customers typically see an average 10% increase in revenue³",
                },
                {
                    contentheading: "Increase check size",
                    contentpara:
                        "On average, check size is 9% higher for customers using Toast Mobile Order & Pay™ vs. standard dine in⁴. With item descriptions, filters, and simple menu modifiers, guests upsell themselves",
                },
                {
                    contentheading: "Reduce comps and voids",
                    contentpara:
                        "Guests can conveniently submit their own order from their own device, reducing costly comps and voids",
                },
            ],
        },
        {
            imagescr: "../Images/L-image4.png",
            heading: "Collect valuable data to keep guests coming back",
            ContentList: [
                {
                    contentheading: "Power your marketing",
                    contentpara:
                        "Collect guest data on each transaction that can be leveraged to power your marketing program, and generate up to $2,800 in sales per campaign with <strong>email marketing<strong>",
                },
                {
                    contentheading: "Increase loyalty sign ups",
                    contentpara:
                        "Boost repeat visits by prompting every guest to sign up for your loyalty program at checkout",
                },
                {
                    contentheading: "Improve menu profitability",
                    contentpara:
                        "Unlock valuable menu insights with Toast Performance Center. Review a weekly snapshot of items like guest favorites and slow sellers",
                },
            ],
        },
    ];

    return (
        <div className="landingPage_wrapper">
            <LandingNav />
            <LandingBanner />
            <div className="container_wrap">
                <TrustedSection />
                <AppCard />
                <div className="landingcard_wrapper padding">
                    {CardList?.map((carddata, index) => {
                        return (
                            <LandingCard
                                cardType={index % 2 == 0 ? "normal" : "reverse"}
                                image={carddata.imagescr}
                                heading={carddata.heading}
                                contentList={carddata.ContentList}
                                key={index}
                            />
                        );
                    })}
                </div>
                <LandingMenu />
                <PackagePlan title="The right package for every restaurant" />
            </div>
            <ReadyToStart />

            <LandingFooter />

            <script
                type="text/javascript"
                id="hs-script-loader"
                async
                defer
                src="//js.hs-scripts.com/22361804.js"
            ></script>
        </div>
    );
};

export default index;
