import React, { useState, useRef } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { HomeIMAGE_BASE_URL } from "../../constant";
import { handleLoginform } from "../../store/api/view-admin/login-register";
import AppButton from "../AppButton";
import { Formik } from "formik";
import * as Yup from "yup";
import { StateType } from "../../store/Reducer/index.reducer";
import Loader from "../Loader";

const Login = () => {
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePassword = (e: React.SyntheticEvent<EventTarget>) => {
        e.preventDefault();
        setPasswordShown(!passwordShown);
    };
    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });
    const loader = useSelector((state: StateType) => state.vlogin.loader);
    const dispatch = useDispatch<any>();

    const ref: any = useRef();
    let navigate = useNavigate();
    const handleSubmit = (values: any) => {
        // values.preventDefault();
        setFormData(values);

        const toSend = {
            email: values.email,
            password: values.password,
        };
        let data = new FormData();
        for (const [key, value] of Object.entries(toSend)) {
            data.append(key, value);
        }
        dispatch(handleLoginform(data, { navigate: navigate }, true));
    };
    const handleChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData({ ...formData, [name]: value });
    };

    const loginInfo = {
        email: "",
        password: "",
    };
    const validationSchema = Yup.object({
        email: Yup.string()
            .email("Invalid email!")
            .required("Please enter your email!"),
        password: Yup.string()
            .trim()
            .min(6, "Password must be atleast 6 letters")
            .required("Please enter your password!"),
    });

    return (
        <div className="form_wrapper">
            <div className="form_container">
                <div className="form_logo">
                    <img src="/Images/logoII.png" alt="logo" />
                </div>
                <h2 className="form_heading">Login to your account</h2>
                <Formik
                    innerRef={ref}
                    initialValues={loginInfo}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                    }) => {
                        const { email, password } = values;
                        return (
                            <form
                                className="login_form"
                                onSubmit={handleSubmit}
                                action=""
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        handleSubmit();
                                    }
                                }}
                            >
                                <div className="form_inputfield">
                                    <input
                                        type="text"
                                        placeholder="Enter your email address"
                                        onChange={handleChange("email")}
                                        name="email"
                                        value={email}
                                    />
                                    {touched.email && errors.email && (
                                        <p className="errmsg">{errors.email}</p>
                                    )}
                                </div>
                                <div className="form_inputfield form_if">
                                    <input
                                        type={
                                            passwordShown ? "text" : "password"
                                        }
                                        placeholder="Enter your password"
                                        onChange={handleChange("password")}
                                        name="password"
                                        value={password}
                                    />
                                    {touched.password && errors.password && (
                                        <p className="errmsg">
                                            {errors.password}
                                        </p>
                                    )}
                                    <button
                                        onClick={togglePassword}
                                        className="signup_togglebutton"
                                    >
                                        {passwordShown ? (
                                            <AiOutlineEye size={20} />
                                        ) : (
                                            <AiOutlineEyeInvisible size={20} />
                                        )}
                                    </button>
                                </div>
                                {/* <div className="forget_password">
                                    <span>Forgot Password?</span>
                                </div> */}
                                <div className="signup_createbtn">
                                    <AppButton
                                        btnType="lgbutton"
                                        htmlType="submit"
                                    >
                                        Login
                                        {loader && (
                                            <Loader classType="loader-nspinner" />
                                        )}
                                    </AppButton>
                                </div>
                            </form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
};

export default Login;
