import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
// import Footer from "../components/Footer";
// import Navbar from "../components/Navbar";
// import Drawer from "../components/Drawer";\
import Drawer from "../Drawer";
import { useDispatch, useSelector } from "react-redux";
import { HomeIMAGE_BASE_URL } from "../../constant";
import { StateType } from "../../store/Reducer/index.reducer";
import { getProfileApi } from "../../store/api/resturantProfile";
import Navbar from "../Navbar";
import Footer from "../Footer";
import AppButton from "../AppButton";
import Loader from "../Loader";

const Index = (props: any) => {
    const dispatch = useDispatch<any>();
    let navigate = useNavigate();
    const { venue } = useParams();
    const [visible, setVisible] = useState(false);
    const [inputTableNum, setInputTableNum] = useState(0);
    const [inputName, setInputName] = useState("");
    const [tablenum, setTableNum] = useState(0);
    const [phoneNum, setPhoneNum] = useState("");
    const [validationMsgTwo, setValidationMsgTwo] = useState("");
    const [validationMsgThree, setValidationMsgThree] = useState("");
    const [submitType, setSubmitType] = useState<"change" | "submit">("change");
    const homeData = useSelector((state: StateType) => state.profile.profile);
    const loader = useSelector((state: StateType) => state.profile.loader);

    const onSubmit = () => {
        if (
            homeData?.profile?.table?.filter(
                (data: any) => data?.key == inputTableNum
            )?.length
        ) {
            setTableNumber(inputTableNum);
            submitType == "submit" &&
                navigate(`/menu/${venue}/category`, { replace: true });
        }
        if (!inputName) {
            setValidationMsgTwo("Please provide your fullname");
            return;
        }
        if (!phoneNum) {
            setValidationMsgThree("Please enter your contact number");
            return;
        }
        {
            setTableNumber(inputTableNum);
            submitType == "submit" &&
                navigate(`/menu/${venue}/category`, { replace: true });
        }
    };
    const showDrawer = (type: "change" | "submit") => {
        setSubmitType(type);
        tablenum && type == "submit"
            ? navigate(`/menu/${venue}/category`, { replace: true })
            : setVisible(true);
    };
    const onChangeTwo = (e: any) => {
        {
            if (!e.target.value) {
                setValidationMsgTwo("Please provide your fullname");
            } else {
                setValidationMsgTwo("");
            }
            setInputName(e.target.value);
        }
    };
    const onChangeThree = (e: any) => {
        {
            if (!e.target.value) {
                setValidationMsgThree("Please enter your contact number");
            } else {
                setValidationMsgThree("");
            }
            setPhoneNum(e.target.value);
        }
    };

    useEffect(() => {
        dispatch(getProfileApi(venue));

        window.scrollTo({
            top: 0,
            left: 0,
        });
    }, [venue]);
    let table = sessionStorage.getItem("tablenum");
    let user = sessionStorage.getItem("inputName");
    let phonenum = sessionStorage.getItem("phone");

    React.useEffect(() => {
        if (table) {
            let newtable = JSON.parse(table);
            setTableNum(newtable);
        }
        if (user) {
            setInputName(user);
        }
        if (phonenum) {
            setPhoneNum(phoneNum);
        }
    }, [table, user, phonenum]);
    const setTableNumber = (tableNum: number) => {
        setInputTableNum(tableNum);
        sessionStorage.setItem("tablenum", JSON.stringify(tableNum));
        sessionStorage.setItem("inputName", inputName);
        sessionStorage.setItem("phone", phoneNum);
    };

    return (
        <>
            <Navbar showNavMenu={true} venue={venue} />
            {loader ? (
                <Loader />
            ) : (
                <div className="welcome-page container">
                    <div className="cover__image">
                        <img
                            src={
                                homeData?.profile?.banner == "/images/user/"
                                    ? "../Images/homecover.png"
                                    : `${HomeIMAGE_BASE_URL}/${homeData?.profile?.banner}`
                            }
                        />
                    </div>
                    <div className="logo-wrapper">
                        <img
                            src={
                                homeData?.profile?.logo == "/images/user/"
                                    ? "../Images/logoIII.png"
                                    : `${HomeIMAGE_BASE_URL}/${homeData?.profile?.logo}`
                            }
                            alt="logo"
                        />
                    </div>
                    <div className="home__content">
                        <div className="button_container">
                            <div className="content-box ">
                                <div className="top-content ">
                                    <div className="table-num ">
                                        {tablenum
                                            ? `${
                                                  homeData?.profile?.type == 2
                                                      ? "Room"
                                                      : homeData?.profile
                                                            ?.type == 3
                                                      ? "Tee"
                                                      : homeData?.profile
                                                            ?.type == 4
                                                      ? "Room"
                                                      : "Table"
                                              } ${tablenum}`
                                            : `Select ${
                                                  homeData?.profile?.type == 2
                                                      ? "Room"
                                                      : homeData?.profile
                                                            ?.type == 4
                                                      ? "Room"
                                                      : homeData?.profile
                                                            ?.type == 3
                                                      ? "Tee"
                                                      : "Table"
                                              } Number`}
                                    </div>
                                    <div
                                        className="table-btn "
                                        onClick={() => showDrawer("change")}
                                    >
                                        {tablenum ? "Change" : "Select"}
                                    </div>
                                </div>
                                <div className="content-btn">
                                    <AppButton
                                        btnType="primary"
                                        onClick={() => showDrawer("submit")}
                                    >
                                        View Dine-in Menu
                                    </AppButton>

                                    <Link to={`/menu/${venue}/feedback`}>
                                        <AppButton btnType="secondary">
                                            Leave a Feedback
                                        </AppButton>
                                    </Link>
                                </div>
                            </div>

                            <Drawer
                                visible={visible}
                                onClose={() => setVisible(false)}
                                onSubmit={onSubmit}
                                onChangeOne={(e: any) =>
                                    setInputTableNum(e.target.value)
                                }
                                onChangeTwo={(e: any) => onChangeTwo(e)}
                                onChangeThree={(e: any) => onChangeThree(e)}
                                validationMsgOne={
                                    !inputTableNum ||
                                    homeData?.profile?.table?.filter(
                                        (data: any) => data.key == inputTableNum
                                    )?.length
                                        ? ""
                                        : `Please enter correct ${
                                              homeData?.profile?.type == 2
                                                  ? "room"
                                                  : homeData?.profile?.type == 3
                                                  ? "Tee"
                                                  : "table"
                                          } number!`
                                }
                                validationMsgTwo={validationMsgTwo}
                                validationMsgThree={validationMsgThree}
                                buttondisabled={
                                    homeData?.profile?.type == 3
                                        ? homeData?.profile?.table?.filter(
                                              (data: any) =>
                                                  data.key == inputTableNum
                                          )?.length &&
                                          inputName.length &&
                                          phoneNum
                                            ? false
                                            : true
                                        : !homeData?.profile?.table?.filter(
                                              (data: any) =>
                                                  data.key == inputTableNum
                                          )?.length
                                }
                            />

                            <div className="footer_wrapper">
                                {/* <div className="rprofile_socialicons">
                                    {homeData?.profile?.social[4] && (
                                        <a
                                            href={
                                                homeData?.profile?.social[4]
                                                    .link
                                            }
                                        >
                                            <img
                                                src="/Images/Facebook.svg"
                                                alt="facebook-icon"
                                            />
                                        </a>
                                    )}
                                    {homeData?.profile?.social[1] && (
                                        <a
                                            href={
                                                homeData?.profile?.social[1]
                                                    .link
                                            }
                                        >
                                            <img
                                                src="/Images/Instagram_logo.svg.webp"
                                                className="socialicon-svg"
                                                alt="insta-icon"
                                            />
                                        </a>
                                    )}
                                    {homeData?.profile?.social[0] && (
                                        <a
                                            href={
                                                homeData?.profile?.social[0]
                                                    .link
                                            }
                                        >
                                            <img
                                                src="/Images/Twitter-logo.svg.webp"
                                                alt="twitter-icon"
                                                className="socialicon-svg"
                                            />
                                        </a>
                                    )}
                                    {homeData?.profile?.social[2] && (
                                        <a
                                            href={
                                                homeData?.profile?.social[2]
                                                    .link
                                            }
                                        >
                                            <img
                                                src="/Images/TikTok.svg"
                                                alt="tiktok-icon"
                                            />
                                        </a>
                                    )}
                                    {homeData?.profile?.social[3] && (
                                        <a
                                            href={
                                                homeData?.profile?.social[3]
                                                    .link
                                            }
                                        >
                                            <img
                                                src="/Images/tripadvisor-logo.svg"
                                                alt="trip-icon"
                                                className="socialicon-svg"
                                            />
                                        </a>
                                    )}
                                </div> */}
                                <Footer />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Index;
