import React, { useState, useRef, useEffect } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { GOOGLE_CLIENTID, HomeIMAGE_BASE_URL } from "../../constant";
import {
    handleSignupform,
    LOGINWITHGOOGLE,
} from "../../store/api/view-admin/signup-register";
import AppButton from "../AppButton";
import { Formik } from "formik";
import * as Yup from "yup";
import Loader from "../Loader";
import { FcGoogle } from "react-icons/fc";
import GoogleLogin, {
    GoogleLoginResponse,
    GoogleLoginResponseOffline,
} from "react-google-login";
import { displayLoader } from "../../store/Action/loader.action";
import { StateType } from "../../store/Reducer/index.reducer";
import { SOCIAL_KEY } from "../../store/const";
import { gapi } from "gapi-script";

const SignUp = () => {
    const [passwordShown, setPasswordShown] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const togglePassword = (e: React.SyntheticEvent<EventTarget>) => {
        e.preventDefault();
        setPasswordShown(!passwordShown);
    };
    const loader = useSelector((state: StateType) => state.vsignup.loader);
    // const googleloader = useSelector(
    //     (state: StateType) => state.googleauth.loader
    // );

    const clientId: any = GOOGLE_CLIENTID;
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        checked: false,
    });
    const dispatch = useDispatch<any>();
    const ref: any = useRef();

    let navigate = useNavigate();
    const handleSubmit = async (values: any) => {
        setFormData(values);

        const toSend = {
            email: values.email,
            password: values.password,
            checked: values.checked,
        };
        let data = new FormData();
        for (const [key, value] of Object.entries(toSend)) {
            data.append(key, value);
        }

        dispatch(handleSignupform(data, { navigate: navigate }, true));
    };
    const handleChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData({ ...formData, [name]: value });
    };
    const signupInfo = {
        email: "",
        password: "",
        checked: false,
    };
    const validationSchema = Yup.object({
        email: Yup.string()
            .email("Invalid email!")
            .required("Please enter your email!"),
        password: Yup.string()
            .trim()
            .min(6, "Password must be atleast 6 letters")
            .required("Please enter your password!"),
        checked: Yup.bool().oneOf(
            [true],
            "You need to accept the terms and conditions"
        ),
    });

    const GoogleLoginSuccess = (response: any) => {
        const data = {
            email: response.profileObj.email,
            google_id: response.googleId,
            name: response.profileObj.name,
        };
        dispatch(LOGINWITHGOOGLE(data, { navigate }, true));
    };
    const GoogleFailure = (response: any) => {};
    useEffect(() => {
        const initClient = () => {
            gapi.client.init({
                clientId: clientId,
                scope: "",
            });
        };
        gapi.load("client:auth2", initClient);
    });
    return (
        <div className="form_wrapper">
            <div className="form_container">
                <div className="form_logo">
                    <img src="/Images/logoII.png" alt="logo" />
                </div>
                <h2 className="form_heading">Create a new account</h2>
                <div className="form_subheading">
                    Or{" "}
                    <Link to="/virtual-menu/login">
                        <span className="login-acc">login to your account</span>
                    </Link>
                </div>
                <Formik
                    innerRef={ref}
                    initialValues={signupInfo}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                    }) => {
                        const { email, password, checked } = values;
                        return (
                            <form
                                className="signup_form"
                                onSubmit={handleSubmit}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        handleSubmit();
                                    }
                                }}
                            >
                                <div className="form_inputfield">
                                    <input
                                        type="text"
                                        placeholder="Enter your email address"
                                        onChange={handleChange("email")}
                                        name="email"
                                        value={email}
                                    />
                                    {touched.email && errors.email && (
                                        <p className="errmsg">{errors.email}</p>
                                    )}
                                </div>
                                <div className="form_inputfield">
                                    <input
                                        type={
                                            passwordShown ? "text" : "password"
                                        }
                                        placeholder="Enter your password"
                                        onChange={handleChange("password")}
                                        name="password"
                                        value={password}
                                    />
                                    {touched.password && errors.password && (
                                        <p className="errmsg">
                                            {errors.password}
                                        </p>
                                    )}
                                    <button
                                        onClick={togglePassword}
                                        className="signup_togglebutton"
                                    >
                                        {passwordShown ? (
                                            <AiOutlineEye size={20} />
                                        ) : (
                                            <AiOutlineEyeInvisible size={20} />
                                        )}
                                    </button>
                                </div>
                                <div className="signup_checkcontent">
                                    <div className="check_content">
                                        <label className="detail__checkbox">
                                            <input
                                                type="checkbox"
                                                onChange={handleChange(
                                                    "checked"
                                                )}
                                                name="checked"
                                                checked={checked}
                                            />

                                            <div className="checkmark"></div>
                                        </label>
                                        <div>
                                            By proceeding, you agree to the
                                            <a href="/terms">
                                                Terms and Conditions & Privacy
                                                Policy
                                            </a>
                                        </div>
                                    </div>
                                    <div>
                                        {touched.checked && errors.checked && (
                                            <p className="errmsg term-condtn_err">
                                                {errors.checked}
                                            </p>
                                        )}
                                    </div>
                                </div>

                                <div className="signup_createbtn">
                                    <AppButton
                                        btnType="lgbutton"
                                        htmlType="submit"
                                    >
                                        Create
                                        {loader && (
                                            <Loader classType="loader-nspinner" />
                                        )}
                                    </AppButton>
                                </div>
                                <div className="socialmedia-button">
                                    <GoogleLogin
                                        clientId={clientId}
                                        render={(renderProps: any) => (
                                            <button
                                                className="continuegoogle"
                                                onClick={renderProps.onClick}
                                                disabled={renderProps.disabled}
                                            >
                                                <FcGoogle className="socialmedia-icon google" />
                                                Continue with Google
                                            </button>
                                        )}
                                        buttonText="Login"
                                        onSuccess={GoogleLoginSuccess}
                                        onFailure={GoogleFailure}
                                        cookiePolicy={"single_host_origin"}
                                    />
                                    {/* {googleloader && (
                                        <Loader classType="nspinner" />
                                    )} */}
                                </div>
                            </form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
};
export default SignUp;
