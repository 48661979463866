import { receiptAction } from "../const";

export interface ReceiptReducer {
    receipt: string;
}
const inititalState: ReceiptReducer = {
    receipt: "",
};

const receiptReducer = (state = inititalState, { type, payload }: any) => {
    switch (type) {
        case receiptAction.GET_RECEIPT:
            return {
                ...state,
                receipt: payload,
            };
        default:
            return state;
    }
};

export default receiptReducer;
