import AppButton from "../AppButton";

const LandingBanner = () => {
    const contentII = [
        {
            dataI: "2M+",
            dataII: "With Toast Mobile Order & Pay™, guests",
        },
        {
            dataI: "37K+",
            dataII: "With Toast Mobile Order & Pay™, guests",
        },
    ];
    return (
        <div className="landingbanner_wrapper">
            <div className="landingbanner_section container_wrap">
                <div className="bannersection_content">
                    <div className="title-image">
                        <h1>
                            Built for restaurants.
                            <br />
                            <div className="frame_img">
                                Built for you.
                                <img src="/Images/Frame.png" alt="profile" />
                            </div>
                            <img
                                src="/Images/star.svg"
                                alt="icon"
                                className="star-icon"
                            />
                        </h1>
                    </div>

                    <p>
                        With Toast Mobile Order & Pay™, guests can easily scan a
                        QR code to browse the menu, order, and pay, all from
                        their mobile device. Plus with Apple Pay, guests can pay
                        in less than 10 seconds.
                    </p>
                    <div className="btn-price">
                        <AppButton btnType="black-heavyborder">
                            Get Demo
                        </AppButton>
                        <a href="#" className="see-price">
                            See Pricing
                        </a>
                    </div>
                    <div className="databox">
                        {contentII?.map((data, index) => {
                            return (
                                <div key={index}>
                                    <div>{data?.dataI}</div>
                                    <div>{data?.dataII}</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="bannersection_image">
                    <img src="../Images/main-img.png" alt="banner_image" />
                </div>
            </div>
        </div>
    );
};

export default LandingBanner;
