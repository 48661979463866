import { Drawer } from "antd";
import React from "react";

import { Link, NavLink } from "react-router-dom";
import { HomeIMAGE_BASE_URL } from "../../constant";
import AppButton from "../AppButton";

const LandingNav = () => {
    const [mobileSize, setMobilesize] = React.useState(false);
    const [visible, setVisible] = React.useState(false);
    const [navlink, setNavlink] = React.useState(false);
    const [navSticky, setNavSticky] = React.useState(false);
    const showDrawer = () => {
        setVisible(!visible);
    };

    const windowResize = () => {
        if (window.innerWidth < 860) {
            setMobilesize(true);
        } else {
            setMobilesize(false);
        }
    };
    const onClose = () => {
        setVisible(false);
    };
    const handleScroll = () => {
        if (window.scrollY > 60) {
            setNavSticky(true);
        } else {
            setNavSticky(false);
        }
    };
    React.useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        window.scrollTo({ top: 0, behavior: "smooth" });
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);
    React.useEffect(() => {
        windowResize();
        window.addEventListener("resize", windowResize);
        return () => window.removeEventListener("resize", windowResize);
    }, [mobileSize]);
    return (
        <div className={!navSticky ? "landing_nav" : "landing_nav active"}>
            <div className="landingnav_innerwrapper">
                <Link to="/">
                    <div className="landing_logo">
                        {mobileSize ? (
                            <img
                                src="../Images/icon-Black.svg"
                                alt="ordr-logo"
                            />
                        ) : (
                            <img
                                src="../Images/black-logo.svg"
                                alt="ordr-logo"
                            />
                        )}
                    </div>
                </Link>
                <div className="landingnav-right">
                    <div className="landingright_btns">
                        <a href={HomeIMAGE_BASE_URL} className="login_link">
                            <AppButton btnType="black-heavyborder">
                                Login
                            </AppButton>
                        </a>
                        {/* <a href="/signup" className="login_link">
                <AppButton btnType="black-heavyborder">Sign Up</AppButton>
                </a> */}
                    </div>

                    {mobileSize ? (
                        <>
                            <div
                                className="burgermenucontainer"
                                role="button"
                                onClick={showDrawer}
                            >
                                <span
                                    className={visible ? "open" : "close"}
                                ></span>
                                <span
                                    className={visible ? "open" : "close"}
                                ></span>
                                <span
                                    className={visible ? "open" : "close"}
                                ></span>
                            </div>

                            <Drawer
                                title={
                                    <div className="landingdrawer_logo">
                                        <img
                                            src="/Images/logoII.png"
                                            alt="ordr-logo"
                                        />
                                    </div>
                                }
                                placement="right"
                                onClose={onClose}
                                visible={visible}
                                className="landing-nav-drawer"
                            >
                                <div className="nav-link">
                                    <ul>
                                        <li>
                                            <NavLink
                                                to="/"
                                                className={({ isActive }) =>
                                                    isActive
                                                        ? "landing_menulink-active"
                                                        : "landing_menulink"
                                                }
                                            >
                                                Home
                                            </NavLink>
                                        </li>
                                        <li>
                                            {" "}
                                            <NavLink
                                                to="/"
                                                className={({ isActive }) =>
                                                    isActive
                                                        ? "landing_menulink-active"
                                                        : "landing_menulink"
                                                }
                                            >
                                                Product
                                            </NavLink>
                                        </li>
                                        <li>
                                            {" "}
                                            <NavLink
                                                to="/"
                                                className={({ isActive }) =>
                                                    isActive
                                                        ? "landing_menulink-active"
                                                        : "landing_menulink"
                                                }
                                            >
                                                Pricing
                                            </NavLink>
                                        </li>
                                        <li>
                                            {" "}
                                            <NavLink
                                                to="/"
                                                className={({ isActive }) =>
                                                    isActive
                                                        ? "landing_menulink-active"
                                                        : "landing_menulink"
                                                }
                                            >
                                                Customers
                                            </NavLink>
                                        </li>
                                        <li>
                                            {" "}
                                            <NavLink
                                                to="/"
                                                className={({ isActive }) =>
                                                    isActive
                                                        ? "landing_menulink-active"
                                                        : "landing_menulink"
                                                }
                                            >
                                                About Us
                                            </NavLink>
                                        </li>

                                        <li>Login</li>
                                        <li>Sign Up</li>
                                    </ul>
                                </div>
                            </Drawer>
                        </>
                    ) : (
                        <div className="nav-link">
                            <ul>
                                <li>
                                    <NavLink
                                        to="/"
                                        className={({ isActive }) =>
                                            isActive
                                                ? "landing_menulink-active"
                                                : "landing_menulink"
                                        }
                                    >
                                        Home
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to=""
                                        className={({ isActive }) =>
                                            isActive
                                                ? "landing_menulink-active"
                                                : "landing_menulink"
                                        }
                                    >
                                        Product
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to=""
                                        className={({ isActive }) =>
                                            isActive
                                                ? "landing_menulink-active"
                                                : "landing_menulink"
                                        }
                                    >
                                        Pricing
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to=""
                                        className={({ isActive }) =>
                                            isActive
                                                ? "landing_menulink-active"
                                                : "landing_menulink"
                                        }
                                    >
                                        Customers
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to=""
                                        className={({ isActive }) =>
                                            isActive
                                                ? "landing_menulink-active"
                                                : "landing_menulink"
                                        }
                                    >
                                        About Us
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default LandingNav;
