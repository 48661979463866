import axios from "axios";
import { API_BASE_URL, VMAPI_BASE_URL } from "../../constant";
import store from "../store";
import Cookies from "cookies-ts";

const cookies = new Cookies();
const api = axios.create({
    baseURL: API_BASE_URL,
});
export default api;
const vmApi = axios.create({
    baseURL: VMAPI_BASE_URL,
});

vmApi.interceptors.request.use(
    async (config: any) => {
        const token = cookies.get("token");
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
            config.headers["content-type"] = `text/html`;
        }
        config.headers["Accept"] = "application/json";

        return config;
    },
    (error) => error
);
export { vmApi };
