import { Dispatch } from "redux";
import { vmApi } from "..";
import { displayLoader } from "../../Action/loader.action";
import { getBusinessType } from "../../Action/viewadmin.action";

export const getBusinessTypeApi = (type: any) => (dispatch: Dispatch) => {
    vmApi
        .get(`/vAdmin/restaurant-type?type=${type}`)
        .then((response) => {
            dispatch(displayLoader(false));
            dispatch(getBusinessType(response.data));
        })
        .catch((err) => {
            dispatch(displayLoader(false));
        });
};
