import React, { useEffect, useState } from "react";
import Drawer from "../../components/Drawer";
import SelectTable from "../../components/SelectTable";
import Navbar from "../../components/Navbar";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { StateType } from "../../store/Reducer/index.reducer";
import { displayLoader } from "../../store/Action/loader.action";
import { getCart } from "../../store/api/cart";
import Loader from "../../components/Loader";

const Index = () => {
    const { currentVenue } = useParams();
    const dispatch = useDispatch<any>();
    const [visible, setVisible] = useState(false);
    const [tablenum, setTableNum] = useState(0);
    const [validationMsgTwo, setValidationMsgTwo] = useState("");
    const [validationMsgThree, setValidationMsgThree] = useState("");
    const [inputTableNum, setInputTableNum] = useState(0);
    const [inputName, setInputName] = useState("");
    const [phoneNum, setPhoneNum] = useState("");
    const [name, setName] = useState("");
    const cart = useSelector((state: StateType) => state.cart);
    const tableData = useSelector((state: StateType) => state.order);
    const homeData = useSelector((state: StateType) => state.profile.profile);

    const onSubmit = () => {
        if (
            homeData?.profile?.table?.filter(
                (data: any) => data?.key == inputTableNum
            )?.length
        ) {
            setTableNumber(inputTableNum);
        }
        if (!inputName) {
            setValidationMsgTwo("Please provide your fullname");
            return;
        }
        if (!phoneNum) {
            setValidationMsgThree("Please enter your contact number");
        }
        {
            setPhoneNum(phoneNum);
        }
        {
            setTableNumber(inputTableNum);
        }
    };
    const onChangeTwo = (e: any) => {
        {
            if (!e.target.value) {
                setValidationMsgTwo("Please provide your fullname");
            } else {
                setValidationMsgTwo("");
            }
            setInputName(e.target.value);
        }
    };
    const onChangeThree = (e: any) => {
        {
            if (!e.target.value) {
                setValidationMsgThree("Please enter your contact number");
            } else {
                setValidationMsgThree("");
            }
            setPhoneNum(e.target.value);
        }
    };

    const showDrawer = () => {
        setVisible(true);
    };
    useEffect(() => {
        dispatch(displayLoader(true));
        dispatch(getCart());
    }, [dispatch]);

    let table = sessionStorage.getItem("tablenum");
    let tablename = sessionStorage.getItem("inputName");
    let phonenum = sessionStorage.getItem("phone");

    React.useEffect(() => {
        if (table) {
            let newtable = JSON.parse(table);
            setTableNum(newtable);
        }
        if (tablename) {
            setName(tablename);
        }
        if (phonenum) {
            setPhoneNum(phonenum);
        }
    }, [table, tablename, phonenum]);

    const setTableNumber = (tableNum: number) => {
        setInputTableNum(tableNum);
        sessionStorage.setItem("tablenum", JSON.stringify(tableNum));
        sessionStorage.setItem("inputName", inputName);
        sessionStorage.setItem("phone", phoneNum);
    };

    return (
        <>
            <Navbar showArrow={true} showDineIn venue={currentVenue} />
            {cart.loader ? (
                <Loader classType="loading-spinner" />
            ) : (
                <div className="order-wrapper">
                    {!tablenum ? (
                        <>
                            <h2>Order details</h2>
                            <div className="info-content">
                                <div className="table_info">
                                    Please select a table number
                                </div>
                                <div
                                    className="table_info table_selectoption"
                                    onClick={showDrawer}
                                >
                                    Select
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="choosed-table_section">
                                <span>
                                    {homeData?.profile?.type == 2
                                        ? "Room number"
                                        : homeData?.profile?.type == 4
                                        ? "Room number"
                                        : homeData?.profile?.type == 3
                                        ? "Tee number"
                                        : "Table number "}
                                </span>
                                <div className="choosedtable__btn">
                                    <button onClick={showDrawer}>
                                        {homeData?.profile?.type == 2
                                            ? "Room "
                                            : homeData?.profile?.type == 4
                                            ? "Room"
                                            : homeData?.profile?.type == 3
                                            ? "Tee"
                                            : "Table"}
                                        {tablenum}
                                    </button>
                                </div>
                            </div>
                        </>
                    )}

                    <SelectTable
                        cart={cart}
                        tablenum={tablenum}
                        phonenum={phoneNum}
                        inputName={name}
                        disabledButton={tablenum ? false : true}
                    />
                </div>
            )}
            <Drawer
                visible={visible}
                onClose={() => setVisible(false)}
                onSubmit={onSubmit}
                onChangeOne={(e) => setInputTableNum(e.target.value)}
                onChangeTwo={(e) => onChangeTwo(e)}
                onChangeThree={(e) => onChangeThree(e)}
                validationMsgOne={
                    !inputTableNum ||
                    homeData?.profile?.table?.filter(
                        (data: any) => data.key == inputTableNum
                    )?.length
                        ? ""
                        : `Please enter correct ${
                              homeData?.profile?.type == 2
                                  ? "room"
                                  : homeData?.profile?.type == 4
                                  ? "room"
                                  : homeData?.profile?.type == 3
                                  ? "Tee"
                                  : "table"
                          } number!`
                }
                validationMsgTwo={validationMsgTwo}
                validationMsgThree={validationMsgThree}
                buttondisabled={
                    homeData?.profile?.type == 3
                        ? homeData?.profile?.table?.filter(
                              (data: any) => data.key == inputTableNum
                          )?.length &&
                          inputName.length &&
                          phoneNum
                            ? false
                            : true
                        : !homeData?.profile?.table?.filter(
                              (data: any) => data.key == inputTableNum
                          )?.length
                }
            />
        </>
    );
};

export default Index;
