import { Dispatch } from "redux";
import { loginForm } from "../../Action/viewadmin.action";
import { vmApi } from "..";
import { notification } from "antd";

import Cookies from "cookies-ts";
import { displayLoader } from "../../Action/loader.action";

export interface loginType {
    email?: string;
    password?: string;
}

const cookies = new Cookies();
export const handleLoginform =
    (data: any, props: any, show: any) => (dispatch: Dispatch) => {
        dispatch(displayLoader(true));
        const { navigate } = props;
        const key = "updatable";

        vmApi
            .post(`/vAdmin/login`, data)
            .then((response: any) => {
                if (response.status == 200) {
                    cookies.set("token", response.data.token);
                    dispatch(displayLoader(false));
                    dispatch(loginForm(response));
                }
                show &&
                    setTimeout(() => {
                        notification.open({
                            key,
                            message: response.data.msg,
                            duration: 12,
                        });
                    }, 1000);

                navigate(`/virtual-menu/about-user`, {
                    replace: true,
                });
            })
            .catch((err: any) => {
                dispatch(displayLoader(false));
                setTimeout(() => {
                    notification.open({
                        key,
                        message: err.response.data.msg,
                    });
                }, 1);
            });
    };
