import { Dispatch } from "redux";
import api from ".";
import { displayLoader } from "../Action/loader.action";
import { getMenu } from "../Action/menu.action";
interface Props {
    id: string;
    tag?: string | number;
}
export const getAllMenu = (data: Props) => (dispatch: Dispatch) => {
    const { id, tag } = data;
    const query = tag ? `tag=${tag}` : "";
    dispatch(displayLoader(true));
    dispatch(getMenu([]));
    api.get(`/category/${id}/products?${query}`)
        .then((response) => {
            dispatch(getMenu(response.data));
            dispatch(displayLoader(false));
        })
        .catch((err) => {
            dispatch(displayLoader(false));
        });
};
